import qs from 'querystring';

import config from 'config';
import axios from 'utils/axios';

const ROOT_URL = config.apiUrl;

const createURL = (urlPath) => `${ROOT_URL}${urlPath}`;

const apis = {
  addRecord: (record) => axios.post(createURL(`/me/dossiers`), record),
  addRow: (numdos, row) => axios.post(createURL(`/me/dossiers/${numdos}`), row),
  closeRecord: (recordId) => axios.post(createURL(`/me/dossiers/${recordId}/close`)),
  createContact: (contact) => axios.post(createURL(`/me/contacts`), contact),
  deleteContact: (contactId) => axios.delete(createURL(`/me/contacts/${contactId}`)),
  getContacts: () => axios.get(createURL(`/me/contacts`)),
  getDocuments: () => axios.get(createURL(`/documents`)),
  getMiniDashboardInfo: () => axios.get(createURL(`/me/mini-dashboard/info`)),
  getNotifications: () => axios.get(createURL(`/me/notifications`)),
  getRecordDetails: (recordId) => axios.get(createURL(`/me/dossiers/${recordId}`)),
  getRecords: ({ status, type }) => axios.get(createURL(`/me/dossiers?status=${status}&type=${type}`)),
  getRecordsShouldBeClosed: () => axios.get(createURL(`/me/dossiers/should-be-closed`)),
  getStats: () => axios.get(createURL(`/me/stats`)),
  login: (credentials) => axios.post(createURL(`/concessionnaires/auth`), credentials),
  markRecordDetailsAsSeen: (recordId) => axios.post(createURL(`/me/dossiers/${recordId}/seen`)),
  me: () => axios.get(createURL(`/me`)),
  searchRecords: ({ fromDate, search, toDate, type }) =>
    axios.get(createURL(`/dossiers/search?${qs.stringify({ from_date: fromDate, search, to_date: toDate, type })}`)),
  updateContact: (contactId, contactData) => axios.put(createURL(`/me/contacts/${contactId}`), contactData),
};

export default apis;

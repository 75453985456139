// material
import { Popover } from '@material-ui/core';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

// ----------------------------------------------------------------------

const ArrowStyle = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    background: theme.palette.background.paper,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderRadius: '0 0 4px 0',
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    content: "''",
    height: 12,
    position: 'absolute',
    right: 20,
    top: -7,
    transform: 'rotate(-135deg)',
    width: 12,
    zIndex: 1,
  },
}));

// ----------------------------------------------------------------------

const MenuPopover = ({ anchorEl, children, sx, ...other }) => (
  <Popover
    PaperProps={{
      sx: {
        border: (theme) => `solid 1px ${theme.palette.grey[500_8]}`,
        boxShadow: (theme) => theme.customShadows.z20,
        ml: 0.5,
        mt: 1.5,
        overflow: 'inherit',
        width: 200,
        ...sx,
      },
    }}
    anchorEl={anchorEl}
    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    {...other}
  >
    <ArrowStyle className="arrow" />

    {children}
  </Popover>
);

MenuPopover.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

export default MenuPopover;

import { Backdrop, Box, Paper, hexToRgb } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import useTutorial from 'hooks/useTutorial';
import React, { useEffect, useRef, useState } from 'react';
import scrollTo from 'utils/scrollTo';

import TutorialPopover from './TutorialPopover';

const TutorialBox = styled(Paper)(({ height, left, open, theme, top, tutorialBoxPadding, width }) => ({
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.grey[800]}`,
  display: open ? 'block' : 'none',
  height: `calc(${height}px + (2*${tutorialBoxPadding}px))`,
  left: `calc(${left}px - ${tutorialBoxPadding}px)`,
  overflow: 'hidden',
  padding: tutorialBoxPadding,
  position: 'absolute',
  top: `calc(${top}px - ${tutorialBoxPadding}px)`,
  width: `calc(${width}px + (2*${tutorialBoxPadding}px))`,
  zIndex: theme.zIndex.tutorialBox,
}));

const PreventEvents = styled('div')(({ disableActions, height, left, theme, top, width }) => ({
  display: disableActions ? 'block' : 'none',
  height,
  left,
  position: 'absolute',
  top,
  width,
  zIndex: theme.zIndex.tutorialBox + 2,
}));

const Tutorial = ({ offsetTop, renderPopover, tutorialBoxPadding }) => {
  const tutorialBoxRef = useRef();
  const [stepRect, setStepRect] = useState({});
  const [anchorEl, setAnchorEl] = useState({});
  const [bodyRect, setBodyRect] = useState({});
  const {
    handleClose,
    handleScrollStart,
    handleScrollStop,
    nextStep,
    onChangeStepIndex,
    open,
    prevStep,
    reset,
    safeStep,
    scrolling,
    size,
    stepIndex,
    stepRef,
  } = useTutorial();

  useEffect(() => {
    (async () => {
      if (safeStep?.ref.current) {
        const bRect = window.document.body.getBoundingClientRect();
        const rect = safeStep.ref.current.getBoundingClientRect();

        if (open) {
          // Handle disable actions
          // if (safeStep?.disableActions) safeStep.ref.current.style.pointerEvents = 'none';

          handleScrollStart();
          if (!safeStep?.fixed) {
            scrollTo(rect.top + Math.abs(bRect.y) - tutorialBoxPadding - offsetTop, () => {
              setStepRect(rect);
              setBodyRect(bRect);
              handleScrollStop();
            });
          } else {
            // Scroll if not fixed item
            setStepRect(rect);
            setBodyRect(bRect);
            handleScrollStop();
          }
        }
      }
    })();
  }, [safeStep, stepIndex, open, stepRef, tutorialBoxPadding, offsetTop, handleScrollStart, handleScrollStop]);

  const { height, left, top: stepTop, width } = stepRect;

  useEffect(() => {
    setAnchorEl(tutorialBoxRef);
  }, [stepTop]);

  const renderContent = safeStep?.stepOptions?.renderContent;
  const top = stepTop + Math.abs(bodyRect.top);

  return (
    <>
      <Backdrop
        onClick={handleClose}
        open={open}
        sx={{
          background: (theme) => `rgba(${hexToRgb(theme.palette.background.default)}, 0.5)`,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      />
      {open && (
        <>
          <PreventEvents
            disableActions={safeStep?.disableActions}
            height={height}
            left={left}
            top={top}
            width={width}
          />
          <TutorialBox
            height={height}
            id="tuto-box"
            left={left}
            open={!scrolling && open}
            ref={tutorialBoxRef}
            top={top}
            tutorialBoxPadding={tutorialBoxPadding}
            width={width}
          >
            {renderContent ? <Box>{renderContent()}</Box> : null}
          </TutorialBox>
          {renderPopover({
            handleClose,
            nextStep,
            onChangeStepIndex,
            open,
            prevStep,
            reset,
            scrolling,
            size,
            step: safeStep?.step,
            stepIndex,
            stepOptions: safeStep?.stepOptions,
            stepRef: anchorEl?.current,
          })}
        </>
      )}
    </>
  );
};

Tutorial.defaultProps = {
  offsetTop: 78,
  renderPopover: TutorialPopover,
  tutorialBoxPadding: 8,
};

export default Tutorial;

import { Box } from '@material-ui/core';
// material
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
// ----------------------------------------------------------------------

export default function CaravanIcon({ ...other }) {
  const theme = useTheme();
  const PRIMARY_LIGHTER = theme.palette.primary.lighter;

  return (
    <Box {...other}>
      <style>
        {`
        .spin {
          transform-origin: center;
          transform-box: fill-box;
          animation: spin 6s linear infinite;
        }
        @keyframes spin {
          100% { transform: rotate(360deg); }
        }
        `}
      </style>
      <svg height="100%" viewBox="0 0 100 100" width="100%" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
        <path
          d="M87.215,56.71C88.35,54.555,89,52.105,89,49.5c0-6.621-4.159-12.257-10.001-14.478 C78.999,35.015,79,35.008,79,35c0-11.598-9.402-21-21-21c-9.784,0-17.981,6.701-20.313,15.757C36.211,29.272,34.638,29,33,29 c-7.692,0-14.023,5.793-14.89,13.252C12.906,43.353,9,47.969,9,53.5C9,59.851,14.149,65,20.5,65c0.177,0,0.352-0.012,0.526-0.022 C21.022,65.153,21,65.324,21,65.5C21,76.822,30.178,86,41.5,86c6.437,0,12.175-2.972,15.934-7.614C59.612,80.611,62.64,82,66,82 c4.65,0,8.674-2.65,10.666-6.518C77.718,75.817,78.837,76,80,76c6.075,0,11-4.925,11-11C91,61.689,89.53,58.727,87.215,56.71z"
          fill={PRIMARY_LIGHTER}
        />
        <path
          d="M34.875,36.5c0,0,3.64,0,6.125,0s4.5-2.015,4.5-4.5c0-2.333-1.782-4.229-4.055-4.455 C41.467,27.364,41.5,27.187,41.5,27c0-2.485-2.015-4.5-4.5-4.5c-1.438,0-2.703,0.686-3.527,1.736 C33.333,21.6,31.171,19.5,28.5,19.5c-2.761,0-5,2.239-5,5c0,0.446,0.077,0.87,0.187,1.282C23.045,25.005,22.086,24.5,21,24.5 c-1.781,0-3.234,1.335-3.455,3.055C17.364,27.533,17.187,27.5,17,27.5c-2.485,0-4.5,2.015-4.5,4.5s2.015,4.5,4.5,4.5s9.5,0,9.5,0 h5.375V37h3V36.5z"
          fill="#fdfcef"
        />
        <path
          d="M28.5,19c-3.033,0-5.5,2.467-5.5,5.5c0,0.016,0,0.031,0,0.047C22.398,24.192,21.71,24,21,24 c-1.831,0-3.411,1.261-3.858,3.005C17.095,27.002,17.048,27,17,27c-2.757,0-5,2.243-5,5s2.243,5,5,5h14.875 c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5H17c-2.206,0-4-1.794-4-4s1.794-4,4-4c0.117,0,0.23,0.017,0.343,0.032l0.141,0.019 c0.021,0.003,0.041,0.004,0.062,0.004c0.246,0,0.462-0.185,0.495-0.437C18.232,26.125,19.504,25,21,25 c0.885,0,1.723,0.401,2.301,1.1c0.098,0.118,0.241,0.182,0.386,0.182c0.078,0,0.156-0.018,0.228-0.056 c0.209-0.107,0.314-0.346,0.254-0.573C24.054,25.218,24,24.852,24,24.5c0-2.481,2.019-4.5,4.5-4.5 c2.381,0,4.347,1.872,4.474,4.263c0.011,0.208,0.15,0.387,0.349,0.45c0.05,0.016,0.101,0.024,0.152,0.024 c0.15,0,0.296-0.069,0.392-0.192C34.638,23.563,35.779,23,37,23c2.206,0,4,1.794,4,4c0,0.117-0.017,0.23-0.032,0.343 l-0.019,0.141c-0.016,0.134,0.022,0.268,0.106,0.373c0.084,0.105,0.207,0.172,0.34,0.185C43.451,28.247,45,29.949,45,32 c0,2.206-1.794,4-4,4h-6.125c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5H41c2.757,0,5-2.243,5-5 c0-2.397-1.689-4.413-4.003-4.877C41.999,27.082,42,27.041,42,27c0-2.757-2.243-5-5-5c-1.176,0-2.293,0.416-3.183,1.164 C33.219,20.76,31.055,19,28.5,19L28.5,19z"
          fill="#472b29"
        />
        <path
          d="M27,26c-1.403,0-2.609,0.999-2.913,2.341C23.72,28.119,23.301,28,22.875,28 c-1.202,0-2.198,0.897-2.353,2.068C20.319,30.022,20.126,30,19.937,30c-1.529,0-2.811,1.2-2.918,2.732 C17.01,32.87,17.114,32.99,17.251,33c0.006,0,0.012,0,0.018,0c0.13,0,0.24-0.101,0.249-0.232 c0.089-1.271,1.151-2.268,2.419-2.268c0.229,0,0.47,0.042,0.738,0.127c0.022,0.007,0.045,0.01,0.067,0.01 c0.055,0,0.11-0.02,0.156-0.054C20.962,30.537,21,30.455,21,30.375c0-1.034,0.841-1.875,1.875-1.875 c0.447,0,0.885,0.168,1.231,0.473c0.047,0.041,0.106,0.063,0.165,0.063c0.032,0,0.063-0.006,0.093-0.019 c0.088-0.035,0.148-0.117,0.155-0.212C24.623,27.512,25.712,26.5,27,26.5c0.208,0,0.425,0.034,0.682,0.107 c0.023,0.007,0.047,0.01,0.07,0.01c0.109,0,0.207-0.073,0.239-0.182c0.038-0.133-0.039-0.271-0.172-0.309 C27.517,26.04,27.256,26,27,26L27,26z"
          fill="#472b29"
        />
        <path
          d="M40.883,27.5c-1.326,0-2.508,0.897-2.874,2.182c-0.038,0.133,0.039,0.271,0.172,0.309 C38.205,29.997,38.228,30,38.25,30c0.109,0,0.209-0.072,0.24-0.182C38.795,28.748,39.779,28,40.883,28 c0.117,0,0.23,0.014,0.342,0.029c0.012,0.002,0.023,0.003,0.035,0.003c0.121,0,0.229-0.092,0.246-0.217 c0.019-0.137-0.077-0.263-0.214-0.281C41.158,27.516,41.022,27.5,40.883,27.5L40.883,27.5z"
          fill="#472b29"
        />
        <path
          d="M15.405,54H5.5C5.224,54,5,53.776,5,53.5S5.224,53,5.5,53h9.905c0.276,0,0.5,0.224,0.5,0.5 S15.682,54,15.405,54z"
          fill="#fff"
        />
        <path
          d="M18.5,54h-1c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5 S18.777,54,18.5,54z"
          fill="#fff"
        />
        <path
          d="M23.491,56H14.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h8.991c0.276,0,0.5,0.224,0.5,0.5 S23.767,56,23.491,56z"
          fill="#fff"
        />
        <path
          d="M12.5,56h-1c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5 S12.777,56,12.5,56z"
          fill="#fff"
        />
        <path
          d="M9.5,56h-2C7.224,56,7,55.776,7,55.5S7.224,55,7.5,55h2c0.276,0,0.5,0.224,0.5,0.5 S9.777,56,9.5,56z"
          fill="#fff"
        />
        <path
          d="M15.5,58h-2c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2c0.276,0,0.5,0.224,0.5,0.5 S15.776,58,15.5,58z"
          fill="#fff"
        />
        <path
          d="M18.5,49c-0.177,0-0.823,0-1,0c-0.276,0-0.5,0.224-0.5,0.5c0,0.276,0.224,0.5,0.5,0.5 c0.177,0,0.823,0,1,0c0.276,0,0.5-0.224,0.5-0.5C19,49.224,18.776,49,18.5,49z"
          fill="#fff"
        />
        <path
          d="M18.5,51c-0.177,0-4.823,0-5,0c-0.276,0-0.5,0.224-0.5,0.5c0,0.276,0.224,0.5,0.5,0.5 c0.177,0,4.823,0,5,0c0.276,0,0.5-0.224,0.5-0.5C19,51.224,18.776,51,18.5,51z"
          fill="#fff"
        />
        <path
          d="M23.5,53c-0.177,0-2.823,0-3,0c-0.276,0-0.5,0.224-0.5,0.5c0,0.276,0.224,0.5,0.5,0.5 c0.177,0,2.823,0,3,0c0.276,0,0.5-0.224,0.5-0.5C24,53.224,23.776,53,23.5,53z"
          fill="#fff"
        />
        <g>
          <path
            d="M34.5,82h-10c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h10c0.276,0,0.5,0.224,0.5,0.5 S34.776,82,34.5,82z"
            fill="#fff"
          />
          <path
            d="M38.5,82h-2c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2c0.276,0,0.5,0.224,0.5,0.5 S38.776,82,38.5,82z"
            fill="#fff"
          />
          <path
            d="M43.5,84h-10c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h10c0.276,0,0.5,0.224,0.5,0.5 S43.777,84,43.5,84z"
            fill="#fff"
          />
          <path
            d="M31.5,84h-1c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5 S31.776,84,31.5,84z"
            fill="#fff"
          />
          <path
            d="M28.375,84H26.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1.875c0.276,0,0.5,0.224,0.5,0.5 S28.651,84,28.375,84z"
            fill="#fff"
          />
          <path
            d="M37.5,80h-5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h5c0.276,0,0.5,0.224,0.5,0.5 S37.777,80,37.5,80z"
            fill="#fff"
          />
          <path
            d="M34.5,86h-2c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2c0.276,0,0.5,0.224,0.5,0.5 S34.776,86,34.5,86z"
            fill="#fff"
          />
        </g>
        <g>
          <path
            d="M77.5,26.5c0,0,1.567,0,3.5,0s3.5-1.567,3.5-3.5c0-1.781-1.335-3.234-3.055-3.455 C81.473,19.366,81.5,19.187,81.5,19c0-1.933-1.567-3.5-3.5-3.5c-1.032,0-1.95,0.455-2.59,1.165 c-0.384-1.808-1.987-3.165-3.91-3.165c-2.209,0-4,1.791-4,4c0,0.191,0.03,0.374,0.056,0.558C67.128,17.714,66.592,17.5,66,17.5 c-1.228,0-2.245,0.887-2.455,2.055C63.366,19.527,63.187,19.5,63,19.5c-1.933,0-3.5,1.567-3.5,3.5s1.567,3.5,3.5,3.5s7.5,0,7.5,0 V27h7V26.5z"
            fill="#fdfcef"
          />
          <path
            d="M79.25,22C79.112,22,79,21.888,79,21.75c0-1.223,0.995-2.218,2.218-2.218 c0.034,0.009,0.737-0.001,1.244,0.136c0.133,0.036,0.212,0.173,0.176,0.306c-0.036,0.134-0.173,0.213-0.306,0.176 c-0.444-0.12-1.1-0.12-1.113-0.118c-0.948,0-1.719,0.771-1.719,1.718C79.5,21.888,79.388,22,79.25,22z"
            fill="#472b29"
          />
          <circle cx="72.5" cy="26.5" fill="#472b29" r=".5" />
          <path
            d="M81,27h-3.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H81c1.654,0,3-1.346,3-3 c0-1.496-1.125-2.768-2.618-2.959c-0.134-0.018-0.255-0.088-0.336-0.196s-0.115-0.244-0.094-0.377C80.975,19.314,81,19.16,81,19 c0-1.654-1.346-3-3-3c-0.85,0-1.638,0.355-2.219,1c-0.125,0.139-0.321,0.198-0.5,0.148c-0.182-0.049-0.321-0.195-0.36-0.379 C74.58,15.165,73.141,14,71.5,14c-1.93,0-3.5,1.57-3.5,3.5c0,0.143,0.021,0.28,0.041,0.418c0.029,0.203-0.063,0.438-0.242,0.54 c-0.179,0.102-0.396,0.118-0.556-0.01C66.878,18.155,66.449,18,66,18c-0.966,0-1.792,0.691-1.963,1.644 c-0.048,0.267-0.296,0.446-0.569,0.405C63.314,20.025,63.16,20,63,20c-1.654,0-3,1.346-3,3s1.346,3,3,3h7.5 c0.276,0,0.5,0.224,0.5,0.5S70.776,27,70.5,27H63c-2.206,0-4-1.794-4-4s1.794-4,4-4c0.059,0,0.116,0.002,0.174,0.006 C63.588,17.82,64.711,17,66,17c0.349,0,0.689,0.061,1.011,0.18C67.176,14.847,69.126,13,71.5,13c1.831,0,3.466,1.127,4.153,2.774 C76.333,15.276,77.155,15,78,15c2.206,0,4,1.794,4,4c0,0.048-0.001,0.095-0.004,0.142C83.739,19.59,85,21.169,85,23 C85,25.206,83.206,27,81,27z"
            fill="#472b29"
          />
          <path
            d="M75.5,26c-0.159,0-0.841,0-1,0c-0.276,0-0.5,0.224-0.5,0.5c0,0.276,0.224,0.5,0.5,0.5 c0.159,0,0.841,0,1,0c0.276,0,0.5-0.224,0.5-0.5C76,26.224,75.776,26,75.5,26z"
            fill="#472b29"
          />
        </g>
        <g>
          <path
            d="M29.043,66.3c-0.782,0-1.442-0.611-1.502-1.391l-0.669-8.702 c-0.059-0.763,0.289-13.752,0.537-22.334c0.198-0.973,1.053-1.674,2.051-1.674h45.397c0.76,0,1.461,0.413,1.83,1.077 l3.998,7.395c0.193,0.347,0.061,0.644-0.005,0.756s-0.261,0.372-0.65,0.372h-6.51l0.901,1.135 c0.837,1.156,7.41,11.11,7.54,12.44l-1.381,9.631c-0.105,0.737-0.747,1.293-1.491,1.293H29.043z"
            fill="#e1e0d8"
          />
          <path
            d="M74.856,32.9c0.506,0,0.972,0.275,1.21,0.703l4.009,7.417c0.008,0.015,0.015,0.027-0.001,0.054 C80.059,41.1,80.046,41.1,80.028,41.1h-5.061h-2.903l1.787,2.246c1.116,1.569,6.861,10.336,7.392,12.05l-1.359,9.512 c-0.056,0.394-0.399,0.692-0.798,0.692H29.043c-0.419,0-0.772-0.327-0.804-0.745l-0.669-8.695 c-0.052-0.907,0.291-13.724,0.536-22.2c0.152-0.62,0.708-1.061,1.353-1.061H74.856 M74.856,31.5H29.459 c-1.351,0-2.509,0.967-2.749,2.297c0,0-0.619,21.384-0.536,22.464l0.669,8.702C26.932,66.112,27.89,67,29.043,67h50.043 c1.098,0,2.029-0.807,2.184-1.894l1.385-9.694c0.185-1.292-7.564-12.756-7.688-12.912h5.061c1.109,0,1.81-1.191,1.271-2.161 l-4.001-7.402C76.806,32.05,75.871,31.5,74.856,31.5L74.856,31.5z"
            fill="#472b29"
          />
          <circle cx="69.5" cy="65.5" fill="#472b29" r="6.5" />
          <path
            className="spin"
            d="M69.5,72.125c-3.653,0-6.625-2.972-6.625-6.625s2.972-6.625,6.625-6.625s6.625,2.972,6.625,6.625 S73.153,72.125,69.5,72.125z M69.5,59.125c-3.515,0-6.375,2.86-6.375,6.375s2.86,6.375,6.375,6.375s6.375-2.86,6.375-6.375 S73.015,59.125,69.5,59.125z"
            fill="#472b29"
          />
          <path
            d="M29,64h-3c-0.55,0-1-0.45-1-1l0,0c0-0.55,0.45-1,1-1h3c0.55,0,1,0.45,1,1l0,0 C30,63.55,29.55,64,29,64z"
            fill="#472b29"
          />
          <path
            d="M82,64h-3c-0.55,0-1-0.45-1-1l0,0c0-0.55,0.45-1,1-1h3c0.55,0,1,0.45,1,1l0,0 C83,63.55,82.55,64,82,64z"
            fill="#472b29"
          />
          <path
            d="M39,56h-4c-0.276,0-0.5-0.224-0.5-0.5S34.724,55,35,55h4c0.276,0,0.5,0.224,0.5,0.5 S39.276,56,39,56z"
            fill="#472b29"
          />
          <path
            d="M75,56H41c-0.276,0-0.5-0.224-0.5-0.5S40.724,55,41,55h34c0.276,0,0.5,0.224,0.5,0.5 S75.276,56,75,56z"
            fill="#472b29"
          />
          <circle cx="37.5" cy="65.5" fill="#472b29" r="6.5" />
          <path
            d="M37.5,72.125c-3.653,0-6.625-2.972-6.625-6.625s2.972-6.625,6.625-6.625s6.625,2.972,6.625,6.625 S41.153,72.125,37.5,72.125z M37.5,59.125c-3.515,0-6.375,2.86-6.375,6.375s2.86,6.375,6.375,6.375s6.375-2.86,6.375-6.375 S41.015,59.125,37.5,59.125z"
            fill="#472b29"
          />
          <circle cx="37.5" cy="65.5" fill="#fff" r="4.5" />
          <path
            d="M37.5,70.125c-2.55,0-4.625-2.075-4.625-4.625s2.075-4.625,4.625-4.625s4.625,2.075,4.625,4.625 S40.05,70.125,37.5,70.125z M37.5,61.125c-2.413,0-4.375,1.963-4.375,4.375s1.962,4.375,4.375,4.375s4.375-1.963,4.375-4.375 S39.913,61.125,37.5,61.125z"
            fill="#472b29"
          />
          <circle cx="37.5" cy="65.5" fill="#cbc9bc" r="2.5" />
          <path
            d="M37.5,68.25c-1.516,0-2.75-1.234-2.75-2.75s1.234-2.75,2.75-2.75c0.448,0,0.892,0.11,1.285,0.318 c0.122,0.065,0.168,0.216,0.104,0.338c-0.064,0.122-0.215,0.169-0.338,0.104c-0.321-0.17-0.685-0.26-1.051-0.26 c-1.241,0-2.25,1.009-2.25,2.25s1.009,2.25,2.25,2.25c0.873,0,1.675-0.512,2.042-1.305c0.058-0.125,0.208-0.18,0.332-0.122 c0.125,0.058,0.18,0.207,0.122,0.332C39.547,67.624,38.567,68.25,37.5,68.25z"
            fill="#472b29"
          />
          <circle cx="69.5" cy="65.5" fill="#fff" r="4.5" />
          <path
            d="M69.5,70.125c-2.55,0-4.625-2.075-4.625-4.625s2.075-4.625,4.625-4.625s4.625,2.075,4.625,4.625 S72.05,70.125,69.5,70.125z M69.5,61.125c-2.413,0-4.375,1.963-4.375,4.375s1.962,4.375,4.375,4.375s4.375-1.963,4.375-4.375 S71.913,61.125,69.5,61.125z"
            fill="#472b29"
          />
          <circle cx="69.5" cy="65.5" fill="#cbc9bc" r="2.5" />
          <path
            d="M67,65.75c-0.138,0-0.25-0.112-0.25-0.25c0-1.516,1.234-2.75,2.75-2.75 c0.448,0,0.892,0.11,1.285,0.318c0.122,0.065,0.168,0.216,0.104,0.338c-0.064,0.122-0.216,0.169-0.338,0.104 c-0.321-0.17-0.685-0.26-1.051-0.26c-1.241,0-2.25,1.009-2.25,2.25C67.25,65.638,67.138,65.75,67,65.75z"
            fill="#472b29"
          />
          <path
            d="M69.5,68.25c-1.061,0-2.009-0.593-2.474-1.547c-0.061-0.124-0.009-0.274,0.115-0.334 c0.123-0.059,0.273-0.01,0.334,0.115c0.381,0.781,1.156,1.266,2.024,1.266c0.873,0,1.675-0.512,2.042-1.305 c0.058-0.125,0.208-0.18,0.332-0.122c0.125,0.058,0.18,0.207,0.122,0.332C71.547,67.624,70.567,68.25,69.5,68.25z"
            fill="#472b29"
          />
          <path
            d="M72,65.75c-0.138,0-0.25-0.112-0.25-0.25c0-0.438-0.125-0.861-0.363-1.226 c-0.075-0.116-0.042-0.271,0.073-0.346c0.117-0.075,0.271-0.043,0.346,0.073c0.291,0.446,0.444,0.964,0.444,1.499 C72.25,65.638,72.138,65.75,72,65.75z"
            fill="#472b29"
          />
          <path
            d="M44.5,73h-11c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h11c0.276,0,0.5,0.224,0.5,0.5 S44.776,73,44.5,73z"
            fill="#472b29"
          />
          <path
            d="M77.5,73h-30c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h30c0.276,0,0.5,0.224,0.5,0.5 S77.776,73,77.5,73z"
            fill="#472b29"
          />
          <g>
            <path
              d="M31.5,73h-5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h5c0.276,0,0.5,0.224,0.5,0.5 S31.776,73,31.5,73z"
              fill="#472b29"
            />
          </g>
          <g>
            <path
              d="M69.926,44.5h-6.598c-0.457,0-0.828,0.371-0.828,0.828v5.344c0,0.457,0.371,0.828,0.828,0.828 h7.977c0.578,0,0.978-0.578,0.775-1.119l-1.379-5.344C70.58,44.714,70.271,44.5,69.926,44.5z"
              fill="#78cdd4"
            />
            <path
              d="M71.305,51.75h-7.977c-0.595,0-1.078-0.484-1.078-1.078v-5.344c0-0.594,0.483-1.078,1.078-1.078 h6.598c0.446,0,0.852,0.281,1.009,0.699l1.387,5.369c0.116,0.306,0.07,0.677-0.132,0.968C71.99,51.577,71.659,51.75,71.305,51.75 z M63.328,44.75c-0.319,0-0.578,0.259-0.578,0.578v5.344c0,0.319,0.259,0.578,0.578,0.578h7.977c0.192,0,0.365-0.091,0.475-0.249 c0.11-0.158,0.134-0.352,0.066-0.533L70.459,45.1c-0.076-0.199-0.294-0.35-0.533-0.35H63.328z"
              fill="#472b29"
            />
          </g>
          <g>
            <path
              d="M72.951,45.703l1.332,5.22c0.13,0.347,0.462,0.578,0.834,0.578h4.716l-4.458-7h-1.59 C73.163,44.5,72.733,45.121,72.951,45.703z"
              fill="#78cdd4"
            />
            <path
              d="M80.289,51.75h-5.172c-0.473,0-0.901-0.297-1.067-0.74l-1.337-5.233 c-0.125-0.347-0.075-0.733,0.136-1.036c0.213-0.307,0.563-0.491,0.937-0.491h1.728L80.289,51.75z M73.785,44.75 c-0.213,0-0.405,0.1-0.526,0.276c-0.121,0.175-0.148,0.39-0.073,0.589l0.008,0.026l1.332,5.219 c0.085,0.222,0.326,0.389,0.591,0.389h4.261l-4.14-6.5H73.785z"
              fill="#472b29"
            />
          </g>
          <g>
            <path d="M78.5,58.5H81l0.5-3h-3c-0.748,0-1,0.252-1,1v1C77.5,58.248,77.752,58.5,78.5,58.5z" fill="#f37e98" />
            <path
              d="M81.212,58.75H78.5c-0.888,0-1.25-0.362-1.25-1.25v-1c0-0.888,0.362-1.25,1.25-1.25h3.295 L81.212,58.75z M78.5,55.75c-0.61,0-0.75,0.14-0.75,0.75v1c0,0.61,0.14,0.75,0.75,0.75h2.288l0.417-2.5H78.5z"
              fill="#472b29"
            />
          </g>
          <g>
            <path d="M31,59.5h-3.5l-0.229-3H31c0.748,0,1,0.252,1,1v1C32,59.248,31.748,59.5,31,59.5z" fill="#f37e98" />
            <path
              d="M31,59.75h-3.731l-0.268-3.5H31c0.888,0,1.25,0.362,1.25,1.25v1 C32.25,59.388,31.888,59.75,31,59.75z M27.732,59.25H31c0.61,0,0.75-0.14,0.75-0.75v-1c0-0.61-0.14-0.75-0.75-0.75h-3.459 L27.732,59.25z"
              fill="#472b29"
            />
          </g>
          <g>
            <path
              d="M59.322,63.75h-7.645c-0.787,0-1.428-0.64-1.428-1.428V45.678c0-0.787,0.641-1.428,1.428-1.428 h7.645c0.787,0,1.428,0.64,1.428,1.428v16.645C60.75,63.11,60.109,63.75,59.322,63.75z M51.678,44.75 c-0.512,0-0.928,0.416-0.928,0.928v16.645c0,0.511,0.416,0.928,0.928,0.928h7.645c0.512,0,0.928-0.416,0.928-0.928V45.678 c0-0.511-0.416-0.928-0.928-0.928H51.678z"
              fill="#472b29"
            />
          </g>
          <g>
            <path
              d="M65.5,39.5h7c1.105,0,2-0.895,2-2v0c0-1.105-0.895-2-2-2h-7c-1.105,0-2,0.895-2,2v0 C63.5,38.605,64.395,39.5,65.5,39.5z"
              fill="#a8dbdb"
            />
            <path
              d="M72.5,39.75h-7c-1.241,0-2.25-1.009-2.25-2.25s1.009-2.25,2.25-2.25h7 c1.241,0,2.25,1.009,2.25,2.25S73.741,39.75,72.5,39.75z M65.5,35.75c-0.965,0-1.75,0.785-1.75,1.75s0.785,1.75,1.75,1.75h7 c0.965,0,1.75-0.785,1.75-1.75s-0.785-1.75-1.75-1.75H65.5z"
              fill="#472b29"
            />
          </g>
          <g>
            <path
              d="M47.866,44.5H31.406c-0.501,0-0.906,0.406-0.906,0.906v5.188c0,0.501,0.406,0.906,0.906,0.906 h16.459c0.35,0,0.634-0.284,0.634-0.634v-5.731C48.5,44.784,48.216,44.5,47.866,44.5z"
              fill="#78cdd4"
            />
            <path
              d="M47.866,51.75H31.406c-0.638,0-1.156-0.519-1.156-1.156v-5.188c0-0.637,0.519-1.156,1.156-1.156 h16.459c0.488,0,0.884,0.397,0.884,0.884v5.731C48.75,51.353,48.354,51.75,47.866,51.75z M31.406,44.75 c-0.362,0-0.656,0.294-0.656,0.656v5.188c0,0.362,0.294,0.656,0.656,0.656h16.459c0.212,0,0.384-0.172,0.384-0.384v-5.731 c0-0.212-0.172-0.384-0.384-0.384H31.406z"
              fill="#472b29"
            />
          </g>
          <g>
            <path
              d="M32.5,39.5h26c1.105,0,2-0.895,2-2v0c0-1.105-0.895-2-2-2h-26c-1.105,0-2,0.895-2,2v0 C30.5,38.605,31.395,39.5,32.5,39.5z"
              fill="#e1e0d8"
            />
            <path
              d="M58.5,39.75h-26c-1.241,0-2.25-1.009-2.25-2.25s1.009-2.25,2.25-2.25h26 c1.241,0,2.25,1.009,2.25,2.25S59.741,39.75,58.5,39.75z M32.5,35.75c-0.965,0-1.75,0.785-1.75,1.75s0.785,1.75,1.75,1.75h26 c0.965,0,1.75-0.785,1.75-1.75s-0.785-1.75-1.75-1.75H32.5z"
              fill="#472b29"
            />
          </g>
        </g>
      </svg>
    </Box>
  );
}

// material
import { CssBaseline } from '@material-ui/core';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

// hooks
import useSettings from '../hooks/useSettings';
import breakpoints from './breakpoints';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import palette from './palette';
import shadows, { customShadows } from './shadows';
//
import shape from './shape';
import typography from './typography';
import zIndex from './zIndex';

// ----------------------------------------------------------------------

const ThemeConfig = ({ children }) => {
  const { themeDirection, themeMode } = useSettings();
  const isLight = themeMode === 'light';

  const themeOptions = useMemo(
    () => ({
      breakpoints,
      customShadows: isLight ? customShadows.light : customShadows.dark,
      direction: themeDirection,
      palette: isLight ? { ...palette.light, mode: 'light' } : { ...palette.dark, mode: 'dark' },
      shadows: isLight ? shadows.light : shadows.dark,
      shape,
      typography,
      zIndex,
    }),
    [isLight, themeDirection]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

export default ThemeConfig;

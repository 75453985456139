import peopleFill from '@iconify/icons-eva/people-fill';
import { Icon } from '@iconify/react';
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
// material
import { alpha } from '@material-ui/core/styles';
import { Delete as DeleteIcon } from '@material-ui/icons';
import IconButtonWithConfirm from 'components/buttons/IconButtonWithConfirm';
import ContactForm from 'components/contacts/ContactForm';
import TutorialElement from 'components/tutorial/TutorialElement';
import useContacts from 'hooks/useContacts';
import React, { useEffect, useRef, useState } from 'react';

import { MButton, MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
// components
import Scrollbar from '../../components/Scrollbar';

// hooks

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;
const PADDING_ITEM = 2.5;

const ContactListItem = ({ contact, onDeleteContact, onSelectContact }) => {
  const { FCT, ID_CONTACT, MAIL, NOM, PRENOM } = contact;
  return (
    <ListItem
      button
      dense
      disableGutters
      key={ID_CONTACT}
      onClick={() => onSelectContact(contact)}
      sx={{
        height: ITEM_HEIGHT,
        px: PADDING_ITEM,
      }}
    >
      <ListItemAvatar sx={{ position: 'relative' }}>
        <Avatar src={PRENOM} />
      </ListItemAvatar>
      <ListItemText
        primary={`${PRENOM} ${NOM}`}
        primaryTypographyProps={{ mb: 0.25, typography: 'subtitle2' }}
        secondary={`${MAIL}${FCT ? ` • ${FCT}` : ''}`}
        secondaryTypographyProps={{ variant: 'caption' }}
      />
      <ListItemSecondaryAction edge="end">
        <IconButtonWithConfirm onClick={() => onDeleteContact(ID_CONTACT)}>
          <DeleteIcon />
        </IconButtonWithConfirm>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const ContactsPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [formOpen, setFormOpen] = useState();
  const [selectedContact, setSelectedContact] = useState();

  const { contacts, deleteContact, getContacts } = useContacts();

  useEffect(() => {
    getContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddContact = () => {
    setFormOpen(true);
  };

  const handleCloseContactForm = () => {
    setSelectedContact(null);
    setFormOpen(false);
  };

  const stepOptions = {
    body: 'Administrez vos contacts facilement et en quelques clics!',
    renderContent: () => (
      <MIconButton>
        <Icon height={20} icon={peopleFill} width={20} />
      </MIconButton>
    ),
    step: 2,
    title: 'Gérez vos Contacts',
  };

  return (
    <>
      <Tooltip title="Mes contacts">
        <TutorialElement fixed stepOptions={stepOptions}>
          <MIconButton
            color={open ? 'primary' : 'default'}
            onClick={handleOpen}
            ref={anchorRef}
            sx={{
              ...(open && {
                bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
              }),
            }}
          >
            <Icon height={20} icon={peopleFill} width={20} />
          </MIconButton>
        </TutorialElement>
      </Tooltip>

      <MenuPopover anchorEl={anchorRef.current} onClose={handleClose} open={open} sx={{ width: 360 }}>
        <Typography sx={{ p: PADDING_ITEM }} variant="h6">
          Contacts <Typography component="span">({contacts.length})</Typography>
        </Typography>

        <Scrollbar sx={{ maxHeight: ITEM_HEIGHT * 8 }}>
          <List>
            {contacts.map((contact) => (
              <ContactListItem
                contact={contact}
                key={contact.ID_CONTACT}
                onDeleteContact={() => deleteContact(contact.ID_CONTACT)}
                onSelectContact={() => setSelectedContact(contact)}
              />
            ))}
          </List>
          <Divider />
          <Box sx={{ p: 2 }}>
            <MButton color="inherit" fullWidth onClick={handleAddContact} variant="outlined">
              Nouveau contact
            </MButton>
          </Box>
        </Scrollbar>
        {(selectedContact || formOpen) && (
          <ContactForm
            contact={selectedContact}
            onClose={handleCloseContactForm}
            open={Boolean(selectedContact) || formOpen}
          />
        )}
      </MenuPopover>
    </>
  );
};

export default ContactsPopover;

import { alpha } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

export default function Pickers(theme) {
  return {
    MuiDateRangePickerViewDesktop: {
      styleOverrides: {
        root: {
          border: `solid 1px ${theme.palette.divider}`,
          borderRadius: theme.shape.borderRadiusMd,
        },
      },
    },

    PrivatePicker: {
      styleOverrides: {
        landscape: {
          border: `solid 1px ${theme.palette.divider}`,
        },
        root: {
          '& .MuiTab-root': {
            '&.Mui-selected': {
              color: theme.palette.common.white,
            },
            color: alpha(theme.palette.common.white, 0.72),
            margin: 0,
          },
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.dark,
            width: '160px !important',
          },
          '& .PrivatePickersToolbar-root': {
            '& .MuiTypography-root': {
              '&.Mui-selected': {
                color: theme.palette.common.white,
              },
              color: alpha(theme.palette.common.white, 0.72),
            },
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          },
          borderRadius: theme.shape.borderRadiusMd,
          overflow: 'hidden',
        },
      },
    },

    PrivatePickersPopper: {
      styleOverrides: {
        paper: {
          borderRadius: theme.shape.borderRadiusMd,
          boxShadow: theme.customShadows.z24,
        },
      },
    },
  };
}

// ----------------------------------------------------------------------

const DISTANCE = 120;

const TRANSITION_ENTER = {
  duration: 0.64,
  ease: [0.43, 0.13, 0.23, 0.96],
};
const TRANSITION_EXIT = {
  duration: 0.48,
  ease: [0.43, 0.13, 0.23, 0.96],
};

export const varFadeIn = {
  animate: { opacity: 1, transition: TRANSITION_ENTER },
  exit: { opacity: 0, transition: TRANSITION_EXIT },
  initial: { opacity: 0 },
};

export const varFadeInUp = {
  animate: { opacity: 1, transition: TRANSITION_ENTER, y: 0 },
  exit: { opacity: 0, transition: TRANSITION_EXIT, y: DISTANCE },
  initial: { opacity: 0, y: DISTANCE },
};

export const varFadeInLeft = {
  animate: { opacity: 1, transition: TRANSITION_ENTER, x: 0 },
  exit: { opacity: 0, transition: TRANSITION_EXIT, x: -DISTANCE },
  initial: { opacity: 0, x: -DISTANCE },
};

export const varFadeInDown = {
  animate: { opacity: 1, transition: TRANSITION_ENTER, y: 0 },
  exit: { opacity: 0, transition: TRANSITION_EXIT, y: -DISTANCE },
  initial: { opacity: 0, y: -DISTANCE },
};

export const varFadeInRight = {
  animate: { opacity: 1, transition: TRANSITION_ENTER, x: 0 },
  exit: { opacity: 0, transition: TRANSITION_EXIT, x: DISTANCE },
  initial: { opacity: 0, x: DISTANCE },
};

import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
// material
import {
  Alert,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// routes
import { PATH_AUTH } from '../../../routes/paths';

// ----------------------------------------------------------------------

const LoginForm = () => {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    password: Yup.string().required('Le mot de passe est obligatoire.'),
    username: Yup.string().required('Le code est obligatoire.'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      remember: true,
      username: '',
    },
    onSubmit: async ({ password, username }, { resetForm, setErrors, setSubmitting }) => {
      try {
        await login({ password, username });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: 'Erreur: Identifiants invalides' });
        }
      }
    },
    validationSchema: LoginSchema,
  });

  const { errors, getFieldProps, handleSubmit, isSubmitting, touched, values } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            autoComplete="username"
            fullWidth
            label="Code"
            type="text"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />

          <TextField
            autoComplete="current-password"
            fullWidth
            label="Mot de passe"
            type={showPassword ? 'text' : 'password'}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleShowPassword}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} to={PATH_AUTH.login} variant="subtitle2">
            Mot de passe oublié ?
          </Link>
        </Stack>

        <LoadingButton fullWidth loading={isSubmitting} size="large" type="submit" variant="contained">
          Se connecter
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
};

export default LoginForm;

import PropTypes from 'prop-types';
import React, { createContext, useReducer } from 'react';

import apis from '../apis';

// ----------------------------------------------------------------------

const initialState = {
  P: {
    '-1': [],
    0: [],
    1: [],
  },
  RP: {
    '-1': [],
    0: [],
    1: [],
  },
  T: {
    '-1': [],
    0: [],
    1: [],
  },
  shouldBeClosedRecords: [],
};

const handlers = {
  ADD_RECORD: (state, { record, status, type }) => ({
    ...state,
    [type]: {
      ...state[type],
      [status]: [record, ...state[type][status]],
    },
  }),
  CLOSE_RECORD: (state, record) => ({
    ...state,
    shouldBeClosedRecords: state.shouldBeClosedRecords.filter((r) => r.ROW_ID !== record.ROW_ID),
  }),
  GET_RECORDS: (state, { records, status, type }) => ({ ...state, [type]: { ...state[type], [status]: records } }),
  GET_SHOULD_BE_CLOSED_RECORDS: (state, shouldBeClosedRecords) => ({
    ...state,
    shouldBeClosedRecords,
  }),
};

const reducer = (state, { payload, type }) => (handlers[type] ? handlers[type](state, payload) : state);

const RecordsContext = createContext({
  ...initialState,
  getRecords: () => Promise.resolve(),
  getShouldBeClosedRecords: () => Promise.resolve(),
});

function RecordsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getRecords = async ({ status, type }) => {
    const { data } = await apis.getRecords({ status, type });
    const records = data?.data;

    dispatch({ payload: { records, status, type }, type: 'GET_RECORDS' });
  };

  const getShouldBeClosedRecords = async () => {
    const { data } = await apis.getRecordsShouldBeClosed();
    const shouldBeClosedRecords = data?.data;

    dispatch({ payload: shouldBeClosedRecords, type: 'GET_SHOULD_BE_CLOSED_RECORDS' });
  };

  const addRecord = async (recordData) => {
    const { data } = await apis.addRecord(recordData);
    const record = data?.data;

    dispatch({ payload: { record, status: '0', type: recordData.type }, type: 'ADD_RECORD' });
    return record;
  };

  const closeRecord = async (recordId) => {
    const { data } = await apis.closeRecord(recordId);
    const record = data?.data;

    dispatch({ payload: record, type: 'CLOSE_RECORD' });
  };

  return (
    <RecordsContext.Provider
      value={{
        ...state,
        addRecord,
        closeRecord,
        getRecords,
        getShouldBeClosedRecords,
      }}
    >
      {children}
    </RecordsContext.Provider>
  );
}

RecordsProvider.propTypes = {
  children: PropTypes.node,
};

export { RecordsContext, RecordsProvider };

import { Chip } from '@material-ui/core';
// material
import { alpha, emphasize, experimentalStyled as styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

// ----------------------------------------------------------------------

const ChipStyle = styled(Chip)(({ styleProps, theme }) => {
  const { clickable, color, onDelete, variant } = styleProps;

  // Filled
  const styleFilled = (c) => ({
    '& .MuiChip-avatar': {
      backgroundColor: theme.palette[c].dark,
      c: theme.palette[c].lighter,
    },
    '& .MuiChip-deleteIcon': {
      '&:hover, &:active': { c: theme.palette[c].contrastText },
      c: alpha(theme.palette[c].contrastText, 0.7),
    },
    '& .MuiChip-icon': { c: 'inherit' },
    backgroundColor: theme.palette[c].main,
    c: theme.palette[c].contrastText,
  });

  const styleFilledClickable = (c) => ({
    '&:hover, &:focus': {
      backgroundColor: emphasize(theme.palette[c].main, theme.palette.action.hoverOpacity),
    },
  });

  const styleFilledDeletable = (c) => ({
    '&:focus': {
      backgroundColor: emphasize(theme.palette[c].main, theme.palette.action.focusOpacity),
    },
  });

  // Outlined
  const styleOutlined = (c) => ({
    '& .MuiChip-avatar': {
      backgroundColor: theme.palette[c].dark,
      color: theme.palette[c].lighter,
    },
    '& .MuiChip-deleteIcon': {
      '&:hover, &:active': { color: theme.palette[c].main },
      color: alpha(theme.palette[c].main, 0.7),
    },
    '& .MuiChip-icon': { color: 'currentColor' },
    '&:focus, &.MuiChip-clickable:hover': {
      backgroundColor: alpha(theme.palette[c].main, theme.palette.action.hoverOpacity),
    },
    border: `1px solid ${theme.palette[c].main}`,
    color: theme.palette[c].main,
  });

  return {
    ...(variant === 'filled'
      ? {
          ...styleFilled(color),
          ...(clickable && { ...styleFilledClickable(color) }),
          ...(onDelete && { ...styleFilledDeletable(color) }),
        }
      : {
          ...styleOutlined(color),
        }),
  };
});

// ----------------------------------------------------------------------

const Mchip = forwardRef(
  ({ clickable: clickableProp, color = 'default', onDelete: onDeleteProp, variant = 'filled', ...other }, ref) => {
    if (color === 'default' || color === 'primary' || color === 'secondary') {
      return (
        <Chip
          clickable={clickableProp && clickableProp}
          color={color}
          onDelete={onDeleteProp && onDeleteProp}
          ref={ref}
          variant={variant}
          {...other}
        />
      );
    }

    return (
      <ChipStyle
        clickable={clickableProp && clickableProp}
        onDelete={onDeleteProp && onDeleteProp}
        ref={ref}
        styleProps={{
          clickable: clickableProp && clickableProp,
          color,
          onDelete: onDeleteProp && onDeleteProp,
          variant,
        }}
        variant={variant}
        {...other}
      />
    );
  }
);

Mchip.propTypes = {
  clickable: PropTypes.bool,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'info', 'success', 'warning', 'error']),
  onDelete: PropTypes.func,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['filled', 'outlined']), PropTypes.string]),
};

export default Mchip;

// ----------------------------------------------------------------------

export default function Fab(theme) {
  return {
    MuiFab: {
      defaultProps: {
        color: 'primary',
      },

      styleOverrides: {
        extended: {
          '& svg': {
            marginRight: theme.spacing(1),
          },
        },
        primary: {},
        root: {
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
            boxShadow: 'none',
          },
          boxShadow: theme.customShadows.z8,
        },
      },

      variants: [
        {
          props: { color: 'primary' },
          style: {
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
            },
            boxShadow: theme.customShadows.primary,
          },
        },
      ],
    },
  };
}

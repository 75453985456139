import { IconButton } from '@material-ui/core';
// material
import { alpha, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

//
import { ButtonAnimate } from '../animate';

// ----------------------------------------------------------------------

const MIconButton = forwardRef(({ children, color = 'default', sx, ...other }, ref) => {
  const theme = useTheme();

  if (color === 'default' || color === 'inherit' || color === 'primary' || color === 'secondary') {
    return (
      <ButtonAnimate>
        <IconButton color={color} ref={ref} sx={sx} {...other}>
          {children}
        </IconButton>
      </ButtonAnimate>
    );
  }

  return (
    <ButtonAnimate>
      <IconButton
        ref={ref}
        sx={{
          '&:hover': {
            bgcolor: alpha(theme.palette[color].main, theme.palette.action.hoverOpacity),
          },
          color: theme.palette[color].main,
          ...sx,
        }}
        {...other}
      >
        {children}
      </IconButton>
    </ButtonAnimate>
  );
});

MIconButton.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary', 'info', 'success', 'warning', 'error']),
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

export default MIconButton;

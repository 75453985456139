import PropTypes from 'prop-types';
import React, { createContext, useReducer } from 'react';

import apis from '../apis';

// ----------------------------------------------------------------------

const initialState = {
  notifications: [],
};

const handlers = {
  GET_NOTIFICATIONS: (state, notifications) => ({
    ...state,
    notifications,
  }),
  REMOVE_NOTIFICATION: (state, recordId) => ({
    ...state,
    notifications: state.notifications.filter((n) => n.NUMDOS !== Number(recordId)),
  }),
};

const reducer = (state, { payload, type }) => (handlers[type] ? handlers[type](state, payload) : state);

const NotificationsContext = createContext({
  ...initialState,
  getNotifications: () => Promise.resolve(),
});

function NotificationsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getNotifications = async () => {
    const { data } = await apis.getNotifications();
    const notifications = data?.data;

    dispatch({ payload: notifications, type: 'GET_NOTIFICATIONS' });
  };

  const removeNotification = async (recordId) => {
    dispatch({ payload: recordId, type: 'REMOVE_NOTIFICATION' });
  };

  return (
    <NotificationsContext.Provider
      value={{
        ...state,
        getNotifications,
        removeNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}

NotificationsProvider.propTypes = {
  children: PropTypes.node,
};

export { NotificationsContext, NotificationsProvider };

// material
import { Box } from '@material-ui/core';
import config from 'config';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';

// ----------------------------------------------------------------------

const { siteName } = config;

const Page = forwardRef(({ children, title, ...other }, ref) => (
  <Box ref={ref} {...other}>
    <Helmet>
      <title>{`${siteName} | ${title}`}</title>
    </Helmet>
    {children}
  </Box>
));

Page.defaultProps = {
  title: 'Votre SAV en ligne',
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;

import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { Icon } from '@iconify/react';
import { AppBar, Box, IconButton, Stack, Toolbar } from '@material-ui/core';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// components
import React from 'react';

import { MHidden } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  WebkitBackdropFilter: 'blur(6px)',
  backdropFilter: 'blur(6px)',
  // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APP_BAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APP_BAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

const DashboardNavbar = ({ onOpenSidebar }) => (
  <RootStyle>
    <ToolbarStyle>
      <Stack alignItems="center" direction="row" spacing={{ sm: 1.5, xs: 0.5 }}>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ color: 'text.primary', mr: 1 }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
      </Stack>

      <Box sx={{ flexGrow: 1 }} />

      <Stack direction="row" spacing={{ sm: 1.5, xs: 0.5 }}>
        {/* <Searchbar /> */}
        <NotificationsPopover />
        <ContactsPopover />
        <AccountPopover />
      </Stack>
    </ToolbarStyle>
  </RootStyle>
);

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default DashboardNavbar;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import stopPropagation from 'utils/stopPropagation';

const IconButtonWithConfirm = ({ children, confirmationProps, onClick, ...props }) => {
  const { cancelText, confirmText, content, title } = confirmationProps;
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleOpenConfirmation = () => {
    setConfirmOpen(true);
  };
  const handleCloseConfirmation = () => {
    setConfirmOpen(false);
  };

  const handleConfirm = () => {
    if (onClick) onClick();
    handleCloseConfirmation();
  };

  const handleCancel = () => {
    handleCloseConfirmation();
  };

  return (
    <>
      <IconButton onClick={stopPropagation(handleOpenConfirmation)} {...props}>
        {children}
      </IconButton>
      <Dialog onClose={stopPropagation(handleCloseConfirmation)} open={confirmOpen}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={stopPropagation(handleCancel)}>{cancelText}</Button>
          <Button autoFocus onClick={stopPropagation(handleConfirm)} variant="contained">
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

IconButtonWithConfirm.defaultProps = {
  confirmationProps: {
    cancelText: 'Annuler',
    confirmText: 'Ok',
    content: 'Cette action est irréversible. Êtes-vous certain de vouloir poursuivre ?',
    title: 'Attention',
  },
};

IconButtonWithConfirm.propTypes = {
  confirmationProps: PropTypes.shape({
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    content: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
  }),
};

export default IconButtonWithConfirm;

// routes
import { useMediaQuery, useTheme } from '@material-ui/core';
import Tutorial from 'components/tutorial/Tutorial';
import ConfigProvider from 'config/ConfigProvider';
import { TutorialProvider } from 'contexts/TutorialContext';
import React from 'react';

import LoadingScreen from './components/LoadingScreen';
import NotistackProvider from './components/NotistackProvider'; // cspell-checker: disable-line
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
// components
import ThemePrimaryColor from './components/ThemePrimaryColor';
// hooks
import useAuth from './hooks/useAuth';
import Router from './routes';
// theme
import ThemeConfig from './theme';

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ConfigProvider />
        <TutorialProvider>
          <Tutorial offsetTop={isMobile ? 64 : 92} />
          <RtlLayout>
            <NotistackProvider>
              <ScrollToTop />
              {isInitialized ? <Router /> : <LoadingScreen />}
            </NotistackProvider>
          </RtlLayout>
        </TutorialProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}

import { withStyles } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

const GlobalStyles = withStyles((theme) => ({
  '@global': {
    '#root': {
      height: '100%',
      width: '100%',
    },
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    // Lazy Load Img
    '.blur-up': {
      WebkitFilter: 'blur(5px)',
      filter: 'blur(5px)',
      transition: 'filter 400ms, -webkit-filter 400ms',
    },

    '.blur-up.lazyloaded ': {
      WebkitFilter: 'blur(0)',
      filter: 'blur(0)',
    },

    a: { color: theme.palette.primary.main },

    body: {
      height: '100%',
      width: '100%',
    },

    html: {
      '-ms-text-size-adjust': '100%',
      '-webkit-overflow-scrolling': 'touch',
      height: '100%',
      width: '100%',
    },

    img: { display: 'block', maxWidth: '100%' },

    input: {
      '&[type=number]': {
        '&::-webkit-inner-spin-button': { WebkitAppearance: 'none', margin: 0 },
        '&::-webkit-outer-spin-button': { WebkitAppearance: 'none', margin: 0 },
        MozAppearance: 'textfield',
      },
    },
    textarea: {
      '&:-ms-input-placeholder': { color: theme.palette.text.disabled },
      '&::-moz-placeholder': { color: theme.palette.text.disabled, opacity: 1 },
      '&::-webkit-input-placeholder': { color: theme.palette.text.disabled },
      '&::placeholder': { color: theme.palette.text.disabled },
    },
  },
}))(() => null);

export default GlobalStyles;

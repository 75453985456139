// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled('span')(({ styleProps, theme }) => {
  const isLight = theme.palette.mode === 'light';
  const { color, variant } = styleProps;

  const styleFilled = (c) => ({
    backgroundColor: theme.palette[c].main,
    color: theme.palette[c].contrastText,
  });

  const styleOutlined = (c) => ({
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette[c].main}`,
    color: theme.palette[c].main,
  });

  const styleGhost = (c) => ({
    backgroundColor: alpha(theme.palette[c].main, 0.16),
    color: theme.palette[c][isLight ? 'dark' : 'light'],
  });

  return {
    alignItems: 'center',
    backgroundColor: theme.palette.grey[300],
    borderRadius: 8,
    color: theme.palette.grey[800],
    cursor: 'default',
    display: 'inline-flex',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
    height: 22,
    justifyContent: 'center',
    lineHeight: 0,
    minWidth: 22,
    padding: theme.spacing(0, 1),
    whiteSpace: 'nowrap',

    ...(color !== 'default'
      ? {
          ...(variant === 'filled' && { ...styleFilled(color) }),
          ...(variant === 'outlined' && { ...styleOutlined(color) }),
          ...(variant === 'ghost' && { ...styleGhost(color) }),
        }
      : {
          ...(variant === 'outlined' && {
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.grey[500_32]}`,
            color: theme.palette.text.primary,
          }),
          ...(variant === 'ghost' && {
            backgroundColor: theme.palette.grey[500_16],
            color: isLight ? theme.palette.text.secondary : theme.palette.common.white,
          }),
        }),
  };
});

// ----------------------------------------------------------------------

export default function Label({ children, color = 'default', variant = 'ghost', ...other }) {
  return (
    <RootStyle styleProps={{ color, variant }} {...other}>
      {children}
    </RootStyle>
  );
}

Label.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'info', 'success', 'warning', 'error']),
  variant: PropTypes.oneOf(['filled', 'outlined', 'ghost']),
};

import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import { Icon } from '@iconify/react';
import { Badge, Box, Divider, List, ListItem, ListItemText, Stack, Typography } from '@material-ui/core';
// material
import { alpha } from '@material-ui/core/styles';
import { LoadingButton } from '@material-ui/lab';
import TypeLabel from 'components/dashboard/records/labels/TypeLabel';
import TutorialElement from 'components/tutorial/TutorialElement';
import useNotifications from 'hooks/useNotifications';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { fDate } from 'utils/formatTime';

import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
// components
import Scrollbar from '../../components/Scrollbar';

// utils
// ----------------------------------------------------------------------

function NotificationItem({ notification, onClose }) {
  return (
    <ListItem
      button
      component={RouterLink}
      disableGutters
      onClick={onClose}
      sx={{
        mt: '1px',
        px: 2.5,
        py: 1.5,
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
      to={`/dashboard/dossiers/${notification.NUMDOS}`}
    >
      <ListItemText
        primary={`#${notification.NUMDOS} - ${notification.OBJ}`}
        secondary={
          <Typography
            sx={{
              alignItems: 'center',
              color: 'text.disabled',
              display: 'flex',
              mt: 0.5,
            }}
            variant="caption"
          >
            <Stack direction="row" spacing={1}>
              <Box component={Icon} icon={clockFill} sx={{ height: 16, mr: 0.5, width: 16 }} />
              {`Le ${fDate(new Date(notification.DTEDERMOD))}`}
              <TypeLabel type={notification.TYPE} />
            </Stack>
          </Typography>
        }
      />
    </ListItem>
  );
}

NotificationItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  notification: PropTypes.object.isRequired,
};

const NotificationsPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const { getNotifications, notifications } = useNotifications();

  const totalUnRead = notifications.length;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let interval = null;
    (async () => {
      await getNotifications();
      interval = setInterval(getNotifications, 30000);
    })();

    return () => interval && clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefreshNotifications = async () => {
    setRefreshing(true);
    await getNotifications();
    setRefreshing(false);
  };

  const stepOptions = {
    body: 'Recevez des notifications au sujet des dossiers en cours et ouvert ! Si vous êtes connecté depuis longtemps, pensez à rafraîchir.',
    renderContent: () => (
      <MIconButton>
        <Icon height={20} icon={bellFill} width={20} />
      </MIconButton>
    ),
    step: 1,
    title: 'Notifications',
  };

  return (
    <>
      <TutorialElement fixed stepOptions={stepOptions}>
        <MIconButton
          color={open ? 'primary' : 'default'}
          onClick={handleOpen}
          ref={anchorRef}
          sx={{
            ...(open && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
            }),
          }}
        >
          <Badge badgeContent={notifications.length} color="error">
            <Icon height={20} icon={bellFill} width={20} />
          </Badge>
        </MIconButton>
      </TutorialElement>
      <MenuPopover anchorEl={anchorRef.current} onClose={handleClose} open={open} sx={{ width: 360 }}>
        <Box sx={{ alignItems: 'center', display: 'flex', px: 2.5, py: 2 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography sx={{ color: 'text.secondary' }} variant="body2">
              {totalUnRead > 0 ? `Vous avez ${totalUnRead} dossiers non lus` : 'Vous êtes à jour'}
            </Typography>
          </Box>
        </Box>

        <Divider />

        {totalUnRead > 0 && (
          <Scrollbar sx={{ maxHeight: { sm: 'auto', xs: 340 } }}>
            <List disablePadding>
              {notifications.map((notification) => (
                <NotificationItem key={notification.id} notification={notification} onClose={handleClose} />
              ))}
            </List>
          </Scrollbar>
        )}
        <Divider />
        <Box sx={{ p: 1 }}>
          <LoadingButton fullWidth loading={refreshing} onClick={handleRefreshNotifications}>
            Rafraîchir
          </LoadingButton>
        </Box>
      </MenuPopover>
    </>
  );
};

export default NotificationsPopover;

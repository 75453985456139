// ----------------------------------------------------------------------

export default function Badge() {
  return {
    MuiBadge: {
      styleOverrides: {
        dot: {
          borderRadius: '50%',
          height: 10,
          width: 10,
        },
      },
    },
  };
}

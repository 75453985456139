import { Dashboard as DashboardIcon, Description as DescriptionIcon } from '@material-ui/icons';
import React from 'react';
// routes
import { PATH_DASHBOARD } from 'routes/paths';

// ----------------------------------------------------------------------

const ICONS = {
  dashboard: <DashboardIcon />,
  documents: <DescriptionIcon />,
};

const sidebarConfig = [
  {
    items: [
      {
        icon: ICONS.dashboard,
        path: PATH_DASHBOARD.overview,
        title: 'Accueil',
      },
      {
        icon: ICONS.documents,
        path: PATH_DASHBOARD.documents,
        title: 'Documents',
      },
    ],
  },
  {
    items: [
      {
        path: PATH_DASHBOARD.pieces,
        title: 'Pièces',
      },
      {
        path: PATH_DASHBOARD.piecesReturn,
        title: 'Retour de pièces',
      },
      {
        path: PATH_DASHBOARD.technical,
        title: 'Techniques',
      },
    ],
    subheader: 'Mes dossiers',
  },
];

export default sidebarConfig;

import React, { Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

// components
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
// guards
import GuestGuard from '../guards/GuestGuard';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import { PATH_DASHBOARD } from './paths';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes(PATH_DASHBOARD.root);

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              left: 0,
              position: 'fixed',
              top: 0,
              width: 1,
              zIndex: 9999,
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      children: [
        { element: <Navigate to={PATH_DASHBOARD.overview} />, path: '/' },
        { element: <DocumentsPage />, path: '/documents' },
        { element: <OverviewPage />, path: '/overview' },
        { element: <RecordsPage defaultStatus="0" pageLabel="Pièces" type="P" />, path: '/pieces' },
        {
          element: <RecordsPage defaultStatus="0" pageLabel="Retour de Pièces" type="RP" />,
          path: '/retour-de-pieces',
        },
        { element: <RecordsPage defaultStatus="0" pageLabel="Techniques" type="T" />, path: '/techniques' },
        { element: <RecordDetailsPage />, path: '/dossiers/:id' },
      ],
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      path: 'dashboard',
    },
    // Main Routes
    {
      children: [
        { element: <Navigate to="/login" />, path: '/' },
        {
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
          path: 'login',
        },
      ],
      path: '*',
    },
    // Errors route
    {
      children: [
        { element: <NotFound />, path: '404' },
        { element: <Navigate replace to="/404" />, path: '*' },
      ],
      element: <LogoOnlyLayout />,
      path: '*',
    },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
// Dashboard
const OverviewPage = Loadable(lazy(() => import('../pages/dashboard/Overview')));
const DocumentsPage = Loadable(lazy(() => import('../pages/dashboard/Documents')));
const RecordsPage = Loadable(lazy(() => import('../pages/dashboard/Records')));
const RecordDetailsPage = Loadable(lazy(() => import('../pages/dashboard/RecordDetails')));
// Main
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

import { Checkbox } from '@material-ui/core';
// material
import { alpha, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

// ----------------------------------------------------------------------

const MCheckbox = forwardRef(({ color = 'primary', sx, ...other }, ref) => {
  const theme = useTheme();

  if (color === 'default' || color === 'primary' || color === 'secondary') {
    return <Checkbox color={color} ref={ref} sx={sx} {...other} />;
  }

  return (
    <Checkbox
      ref={ref}
      sx={{
        '&.Mui-checked': {
          color: theme.palette[color].main,
        },
        '&.MuiCheckbox-indeterminate': {
          color: theme.palette[color].main,
        },
        '&:hover, &.Mui-checked:hover': {
          backgroundColor: alpha(theme.palette[color].main, theme.palette.action.hoverOpacity),
        },
        ...sx,
      }}
      {...other}
    />
  );
});

MCheckbox.defaultProps = {
  color: 'default',
  sx: undefined,
};

MCheckbox.propTypes = {
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'info', 'success', 'warning', 'error']),
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

export default MCheckbox;

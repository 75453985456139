const scrollTo = (offset, callback) => {
  const onScroll = function () {
    const fixedOffset = offset.toFixed();
    const actualOffset = Number(Math.abs(window.document.body.getBoundingClientRect().top.toFixed()));
    let triggered = false;

    if (!triggered && actualOffset === Number(fixedOffset)) {
      window.removeEventListener('scroll', onScroll);
      callback();
    }
    if (
      fixedOffset >= actualOffset &&
      actualOffset >= window.document.documentElement.scrollHeight - window.innerHeight
    ) {
      triggered = true;
      window.removeEventListener('scroll', onScroll);
      callback();
    }
  };

  window.addEventListener('scroll', onScroll);
  onScroll();
  window.scrollTo({
    behavior: 'smooth',
    top: offset,
  });
};

export default scrollTo;

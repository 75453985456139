import { Button } from '@material-ui/core';
import axios from 'axios';
import React from 'react';

// eslint-disable-next-line import/no-cycle
import { setSession } from './jwt';
import snackbar from './snackbar';

// ----------------------------------------------------------------------

const axiosInstance = axios.create();
axiosInstance.defaults.headers.common['content-type'] = 'application/json';

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401 && error?.response?.data?.error === 'UNAUTHORIZED') {
      setSession(null);
      const root = document.getElementById('root');
      root.style.transition = 'all 0.5s ease';
      root.style.filter = 'blur(5px)';
      root.style.pointerEvents = 'none';
      root.style.overflow = 'hidden';
      snackbar.enqueue("Votre session s'est terminée", {
        action: () => (
          <Button
            color="error"
            onClick={() => {
              window.location.href = '/login';
            }}
            variant="outlined"
          >
            Se reconnecter
          </Button>
        ),
        persist: true,
        variant: 'error',
      });
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;

import PropTypes from 'prop-types';
import React, { createContext, useReducer } from 'react';

import apis from '../apis';

// ----------------------------------------------------------------------

const initialState = {
  contacts: [],
};

const handlers = {
  CONTACT_CREATED: (state, payload) => ({ ...state, contacts: [...state.contacts, payload] }),
  CONTACT_DELETED: (state, payload) => ({
    ...state,
    contacts: state.contacts.filter((c) => c.ID_CONTACT !== payload.ID_CONTACT),
  }),
  CONTACT_UPDATED: (state, payload) => ({
    ...state,
    contacts: state.contacts.map((c) => (c.ID_CONTACT === payload.ID_CONTACT ? payload : c)),
  }),
  GET_CONTACTS: (state, payload) => ({ ...state, contacts: payload }),
};

const reducer = (state, { payload, type }) => (handlers[type] ? handlers[type](state, payload) : state);

const ContactsContext = createContext({
  ...initialState,
  getContacts: () => Promise.resolve(),
});

function ContactsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getContacts = async () => {
    const { data } = await apis.getContacts();
    const contacts = data?.data;

    dispatch({ payload: contacts, type: 'GET_CONTACTS' });
  };

  const createContact = async (contactData) => {
    const { data } = await apis.createContact(contactData);
    const contact = data?.data;

    dispatch({ payload: contact, type: 'CONTACT_CREATED' });
  };

  const updateContact = async (contactId, contactData) => {
    const { data } = await apis.updateContact(contactId, contactData);
    const contact = data?.data;

    dispatch({ payload: contact, type: 'CONTACT_UPDATED' });
  };

  const deleteContact = async (contactId) => {
    const { data } = await apis.deleteContact(contactId);
    const contact = data?.data;

    dispatch({ payload: contact, type: 'CONTACT_DELETED' });
  };

  const getContactInfo = (id) => state.contacts.find((contact) => contact.ID_CONTACT === id);

  return (
    <ContactsContext.Provider
      value={{
        ...state,
        createContact,
        deleteContact,
        getContactInfo,
        getContacts,
        updateContact,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
}

ContactsProvider.propTypes = {
  children: PropTypes.node,
};

export { ContactsContext, ContactsProvider };

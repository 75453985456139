import PropTypes from 'prop-types';
import React, { createContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useLocation } from 'react-router';

// ----------------------------------------------------------------------

const initialState = {
  steps: [],
};

const handlers = {
  ADD_ELEMENT: (state, { disableActions, fixed, on, ref, step, stepOptions }) => ({
    ...state,
    steps: [...state.steps.filter((s) => s.step !== step), { disableActions, fixed, on, ref, step, stepOptions }],
  }),
};

const reducer = (state, { payload, type }) => (handlers[type] ? handlers[type](state, payload) : state);

const TutorialContext = createContext({
  ...initialState,
  nextStep: () => Promise.resolve(),
  prevStep: () => Promise.resolve(),
});

function TutorialProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [scrolling, setScrolling] = useState(false);
  const { pathname } = useLocation();

  const filteredSteps = useMemo(() => state.steps.filter((step) => step.on === pathname), [state.steps, pathname]);
  const orderedSteps = useMemo(
    () =>
      filteredSteps.sort((a, b) => {
        if (a.step < b.step) return -1;
        return 1;
      }),
    [filteredSteps]
  );

  const nextStep = () => setStepIndex((i) => (i < orderedSteps.length - 1 ? i + 1 : 0));
  const prevStep = () => setStepIndex((i) => (i <= 0 ? orderedSteps.length - 1 : i - 1));

  const reset = () => setStepIndex(0);

  const addElement = ({ disableActions, fixed, on, ref, step, stepOptions }) => {
    //
    dispatch({
      payload: {
        disableActions,
        fixed,
        on,
        ref,
        step,
        stepOptions,
      },
      type: 'ADD_ELEMENT',
    });
  };

  const onChangeStepIndex = (index) => setStepIndex(index);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setStepIndex(0);
  }, [pathname]);

  const safeStep = useMemo(() => orderedSteps?.[stepIndex] || orderedSteps?.[0], [stepIndex, orderedSteps]);

  return (
    <TutorialContext.Provider
      value={{
        ...state,
        addElement,
        currentStep: safeStep?.step,
        handleClose,
        handleOpen,
        handleScrollStart: () => setScrolling(true),
        handleScrollStop: () => setScrolling(false),
        nextStep,
        onChangeStepIndex,
        open,
        orderedSteps,
        prevStep,
        reset,
        safeStep,
        scrolling,
        size: orderedSteps.length,
        stepIndex,
        stepRef: safeStep?.ref,
      }}
    >
      {children}
    </TutorialContext.Provider>
  );
}

TutorialProvider.propTypes = {
  children: PropTypes.node,
};

export { TutorialContext, TutorialProvider };

// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
// components
import React from 'react';
import { Outlet, Link as RouterLink } from 'react-router-dom';

import Logo from '../components/Logo';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  left: 0,
  lineHeight: 0,
  padding: theme.spacing(3, 3, 0),
  position: 'absolute',
  top: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

const LogoOnlyLayout = () => (
  <>
    <HeaderStyle>
      <RouterLink to="/">
        <Logo />
      </RouterLink>
    </HeaderStyle>
    <Outlet />
  </>
);

export default LogoOnlyLayout;

import { formatDistanceToNow } from 'date-fns';
import { format } from 'date-fns-tz';
import { fr } from 'date-fns/locale';

const locale = fr;
const timeZone = 'Europe/Paris';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy', { locale, timeZone });
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm', { locale, timeZone });
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p', { locale, timeZone });
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale,
  });
}

import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import infoFill from '@iconify/icons-eva/info-fill';
import { Icon } from '@iconify/react';
import { Box } from '@material-ui/core';
// material
import { alpha, makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => {
  const isLight = theme.palette.mode === 'light';

  const createStyle = {
    backgroundColor: `${theme.palette.background.paper} !important`,
    color: `${theme.palette.text.primary} !important`,
  };

  return {
    action: {
      '& svg': {
        '&:hover': { opacity: 1 },
        height: 20,
        opacity: 0.48,
        width: 20,
      },
      marginRight: -4,
    },
    containerRoot: {
      '& .MuiCollapse-wrapperInner': {
        width: '100%',
      },
      pointerEvents: 'unset',
    },
    contentRoot: {
      backgroundColor: theme.palette.grey[isLight ? 900 : 0],
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.customShadows.z8,
      color: theme.palette.grey[isLight ? 0 : 800],
      margin: theme.spacing(0.25, 0),
      padding: theme.spacing(1.5),
      width: '100%',
    },
    error: { ...createStyle },
    info: { ...createStyle },
    message: {
      fontWeight: theme.typography.fontWeightMedium,
      padding: 0,
    },
    success: { ...createStyle },
    warning: { ...createStyle },
  };
});

// ----------------------------------------------------------------------

const SnackbarIcon = ({ color, icon }) => (
  <Box
    component="span"
    sx={{
      alignItems: 'center',
      bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      borderRadius: 1.5,
      color: `${color}.main`,
      display: 'flex',
      height: 40,
      justifyContent: 'center',
      mr: 1.5,
      width: 40,
    }}
  >
    <Icon height={24} icon={icon} width={24} />
  </Box>
);

SnackbarIcon.propTypes = {
  color: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object,
};

const NotistackProvider = ({ children }) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      autoHideDuration={3000}
      // preventDuplicate
      classes={{
        action: classes.action,
        containerRoot: classes.containerRoot,
        contentRoot: classes.contentRoot,
        message: classes.message,
        variantError: classes.error,
        variantInfo: classes.info,
        variantSuccess: classes.success,
        variantWarning: classes.warning,
      }}
      dense
      iconVariant={{
        error: <SnackbarIcon color="error" icon={infoFill} />,
        info: <SnackbarIcon color="info" icon={alertCircleFill} />,
        success: <SnackbarIcon color="success" icon={checkmarkCircle2Fill} />,
        warning: <SnackbarIcon color="warning" icon={alertTriangleFill} />,
      }}
      maxSnack={5}
    >
      {children}
    </SnackbarProvider>
  );
};

NotistackProvider.propTypes = {
  children: PropTypes.node,
};

export default NotistackProvider;

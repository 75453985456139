import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill'; // cspell-checker: disable-line
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill'; // cspell-checker: disable-line
import { Icon } from '@iconify/react'; // cspell-checker: disable-line
import { Box, Collapse, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core';
// material
import { alpha, experimentalStyled as styled, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => <ListSubheader disableGutters disableSticky {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(5),
  })
);

const ListItemStyle = styled((props) => <ListItem button disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  '&:before': {
    backgroundColor: theme.palette.primary.main,
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 3,
  },
  color: theme.palette.text.secondary,
  height: 48,
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  position: 'relative',
  textTransform: 'capitalize',
}));

const ListItemIconStyle = styled(ListItemIcon)({
  alignItems: 'center',
  display: 'flex',
  height: 22,
  justifyContent: 'center',
  width: 22,
});

// ----------------------------------------------------------------------

const NavItem = ({ active, item }) => {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { children, icon, info, path, title } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    '&:before': { display: 'block' },
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>
            {icon || (
              <Box
                component="span"
                sx={{
                  alignItems: 'center',
                  bgcolor: 'text.disabled', // cspell-checker: disable-line
                  borderRadius: '50%',
                  display: 'flex',
                  height: 4,
                  justifyContent: 'center',
                  transition: (th) => th.transitions.create('transform'),
                  width: 4,
                  ...(isActiveRoot && {
                    bgcolor: 'primary.main', // cspell-checker: disable-line
                    transform: 'scale(2)',
                  }),
                }}
              />
            )}
          </ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ height: 16, ml: 1, width: 16 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((navItem) => {
              const { path: itemPath, title: itemTitle } = navItem;
              const isActiveSub = active(itemPath);

              return (
                <ListItemStyle
                  component={RouterLink}
                  key={itemTitle}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                  to={itemPath}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        alignItems: 'center',
                        bgcolor: 'text.disabled', // cspell-checker: disable-line
                        borderRadius: '50%',
                        display: 'flex',
                        height: 4,
                        justifyContent: 'center',
                        transition: (th) => th.transitions.create('transform'),
                        width: 4,
                        ...(isActiveSub && {
                          bgcolor: 'primary.main', // cspell-checker: disable-line
                          transform: 'scale(2)',
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
      to={path}
    >
      <ListItemIconStyle>
        {icon || (
          <Box
            component="span"
            sx={{
              alignItems: 'center',
              bgcolor: 'text.disabled', // cspell-checker: disable-line
              borderRadius: '50%',
              display: 'flex',
              height: 4,
              justifyContent: 'center',
              transition: (th) => th.transitions.create('transform'),
              width: 4,
              ...(isActiveRoot && {
                bgcolor: 'primary.main', // cspell-checker: disable-line
                transform: 'scale(2)',
              }),
            }}
          />
        )}
      </ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
};

NavItem.propTypes = {
  active: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
};

const NavSection = ({ navConfig, ...other }) => {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ end: false, path }, pathname) : false);

  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { items, subheader } = list;
        return (
          <List disablePadding key={subheader || Math.random()}>
            <ListSubheaderStyle>{subheader}</ListSubheaderStyle>
            {items.map((item) => (
              <NavItem active={match} item={item} key={item.title} />
            ))}
          </List>
        );
      })}
    </Box>
  );
};

NavSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  navConfig: PropTypes.array,
};

export default NavSection;

import { ButtonGroup } from '@material-ui/core';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

// ----------------------------------------------------------------------

const ButtonGroupStyle = styled(ButtonGroup)(({ styleProps, theme }) => {
  const { color, variant } = styleProps;

  const styleContained = (c) => ({
    '& .MuiButtonGroup-grouped': {
      '&:hover': {
        backgroundColor: theme.palette[c].dark,
      },
      backgroundColor: theme.palette[c].main,
      borderColor: `${theme.palette[c].dark} !important`,
      color: theme.palette[c].contrastText,
    },
    boxShadow: theme.customShadows[c],
  });

  const styleOutlined = (c) => ({
    '& .MuiButtonGroup-grouped': {
      '&:hover': {
        backgroundColor: alpha(theme.palette[c].main, theme.palette.action.hoverOpacity),
        borderColor: `${theme.palette[c].main} !important`,
      },
      borderColor: `${alpha(theme.palette[c].main, 0.48)} !important`,
      color: theme.palette[c].main,
    },
  });

  const styleText = (c) => ({
    '& .MuiButtonGroup-grouped': {
      '&:hover': {
        backgroundColor: alpha(theme.palette[c].main, theme.palette.action.hoverOpacity),
      },
      borderColor: `${theme.palette[c].main} !important`,
      color: theme.palette[c].main,
    },
  });
  return {
    ...(variant === 'contained' && { ...styleContained(color) }),
    ...(variant === 'outlined' && { ...styleOutlined(color) }),
    ...(variant === 'text' && { ...styleText(color) }),
  };
});

// ----------------------------------------------------------------------

function MButtonGroup({ children, color = 'primary', variant = 'outlined', ...other }) {
  if (color === 'inherit' || color === 'primary' || color === 'secondary') {
    return (
      <ButtonGroup color={color} variant={variant} {...other}>
        {children}
      </ButtonGroup>
    );
  }

  return (
    <ButtonGroupStyle styleProps={{ color, variant }} variant={variant} {...other}>
      {children}
    </ButtonGroupStyle>
  );
}

MButtonGroup.defaultProps = {
  children: PropTypes.node,
  color: 'inherit',
  variant: 'text',
};

MButtonGroup.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'info', 'success', 'warning', 'error']),
  variant: PropTypes.oneOfType([PropTypes.oneOf(['contained', 'outlined', 'text']), PropTypes.string]),
};

export default MButtonGroup;

// material
import { Box } from '@material-ui/core';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
//
import React from 'react';

import { varWrapEnter } from './variants';

// ----------------------------------------------------------------------

const MotionContainer = ({ children, open, ...other }) => (
  <Box animate={open ? 'animate' : 'exit'} component={motion.div} initial={false} variants={varWrapEnter} {...other}>
    {children}
  </Box>
);

MotionContainer.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
};

export default MotionContainer;

// material
import { Box } from '@material-ui/core';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
//
import React from 'react';

import { varMediumClick, varSmallClick } from './variants';

// ----------------------------------------------------------------------

const ButtonAnimate = ({ children, mediumClick = false, sx, ...other }) => (
  <Box
    component={motion.div}
    sx={{ display: 'inline-flex', ...sx }}
    variants={mediumClick ? varMediumClick : varSmallClick}
    whileHover="hover"
    whileTap="tap"
    {...other}
  >
    {children}
  </Box>
);

ButtonAnimate.propTypes = {
  children: PropTypes.node,
  mediumClick: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

export default ButtonAnimate;

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import frLocales from './fr.json';

// ----------------------------------------------------------------------

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    defaultNS: 'translations',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    lng: localStorage.getItem('i18nextLng') || 'en',
    ns: ['translations'],
    resources: {
      fr: { translations: frLocales },
    },
  });

export default i18n;

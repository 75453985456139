// ----------------------------------------------------------------------

export default function Slider(theme) {
  return {
    MuiSlider: {
      styleOverrides: {
        markLabel: {
          color: theme.palette.text.disabled,
          fontSize: 13,
        },
        root: {
          '&.Mui-disabled': {
            color: theme.palette.action.disabled,
          },
        },
      },
    },
  };
}

import { Button } from '@material-ui/core';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

// ----------------------------------------------------------------------

const ButtonStyle = styled(Button)(({ styleProps, theme }) => {
  const { color, variant } = styleProps;

  const styleContained = (c) => ({
    '&:hover': {
      backgroundColor: theme.palette[c].dark,
    },
    backgroundColor: theme.palette[c].main,
    boxShadow: theme.customShadows[c],
    color: theme.palette[c].contrastText,
  });

  const styleOutlined = (c) => ({
    '&:hover': {
      backgroundColor: alpha(theme.palette[c].main, theme.palette.action.hoverOpacity),
      border: `1px solid ${theme.palette[c].main}`,
    },
    border: `1px solid ${alpha(theme.palette[c].main, 0.48)}`,
    color: theme.palette[c].main,
  });

  const styleText = (c) => ({
    '&:hover': {
      backgroundColor: alpha(theme.palette[c].main, theme.palette.action.hoverOpacity),
    },
    color: theme.palette[c].main,
  });
  return {
    ...(variant === 'contained' && { ...styleContained(color) }),
    ...(variant === 'outlined' && { ...styleOutlined(color) }),
    ...(variant === 'text' && { ...styleText(color) }),
  };
});

// ----------------------------------------------------------------------

const MButton = forwardRef(({ children, color = 'primary', variant = 'text', ...other }, ref) => {
  if (color === 'inherit' || color === 'primary' || color === 'secondary') {
    return (
      <Button color={color} ref={ref} variant={variant} {...other}>
        {children}
      </Button>
    );
  }

  return (
    <ButtonStyle ref={ref} styleProps={{ color, variant }} variant={variant} {...other}>
      {children}
    </ButtonStyle>
  );
});

MButton.defaultProps = {
  children: undefined,
  color: 'inherit',
  variant: 'text',
};

MButton.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'info', 'success', 'warning', 'error']),
  variant: PropTypes.oneOfType([PropTypes.oneOf(['contained', 'outlined', 'text']), PropTypes.string]),
};

export default MButton;

import { Box } from '@material-ui/core';
// material
import { alpha, makeStyles, experimentalStyled as styled } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import NProgress from 'nprogress';
import React, { useEffect, useMemo } from 'react';

// ----------------------------------------------------------------------

const nprogressStyle = makeStyles((theme) => ({
  '@global': {
    '#nprogress': {
      '& .bar': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`,
        height: 2,
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: theme.zIndex.snackbar,
      },
      '& .peg': {
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`,
        display: 'block',
        height: '100%',
        opacity: 1,
        position: 'absolute',
        right: 0,
        transform: 'rotate(3deg) translate(0px, -4px)',
        width: 100,
      },
      pointerEvents: 'none',
    },
  },
}));

const RootStyle = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------

export default function LoadingScreen({ ...other }) {
  nprogressStyle();

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return (
    <RootStyle {...other}>
      <Box
        animate={{
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
          opacity: [0.25, 1, 1, 1, 0.25],
          rotate: [270, 0, 0, 270, 270],
          scale: [1.2, 1, 1, 1.2, 1.2],
        }}
        component={motion.div}
        sx={{
          border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
          borderRadius: '25%',
          height: 100,
          position: 'absolute',
          width: 100,
        }}
        transition={{ duration: 3.2, ease: 'linear', repeat: Infinity }}
      />

      <Box
        animate={{
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          rotate: [0, 270, 270, 0, 0],
          scale: [1, 1.2, 1.2, 1, 1],
        }}
        component={motion.div}
        sx={{
          border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
          borderRadius: '25%',
          height: 120,
          position: 'absolute',
          width: 120,
        }}
        transition={{
          duration: 3.2,
          ease: 'linear',
          repeat: Infinity,
        }}
      />
    </RootStyle>
  );
}

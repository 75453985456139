import { Box, Button, Drawer, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import InstallApp from 'components/InstallApp';
import useTutorial from 'hooks/useTutorial';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { DocIcon } from '../../assets';
import { MHidden } from '../../components/@material-extend';
import Logo from '../../components/Logo';
import MyAvatar from '../../components/MyAvatar';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
import useAuth from '../../hooks/useAuth';
import sidebarConfig from './SidebarConfig';

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.grey[500_12],
  borderRadius: theme.shape.borderRadiusSm,
  display: 'flex',
  padding: theme.spacing(2, 2.5),
}));

const DocStyle = styled('div')(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'light' ? alpha(theme.palette.primary.main, 0.08) : theme.palette.primary.lighter,
  borderRadius: theme.shape.borderRadiusMd,
  padding: theme.spacing(2.5),
}));

const DashboardSidebar = ({ isOpenSidebar, onCloseSidebar }) => {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const theme = useTheme();
  const { handleOpen, size } = useTutorial();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      // cspell-checker: disable-next-line
      sx={{ '& .simplebar-content': { display: 'flex', flexDirection: 'column', height: '100%' }, height: '100%' }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/">
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 2, mx: 2.5 }}>
        <InstallApp />
      </Box>
      <Box sx={{ mb: 2, mx: 2.5 }}>
        <Link component={RouterLink} to="/" underline="none">
          <AccountStyle>
            <MyAvatar />
            <Box sx={{ ml: 2, overflowX: 'hidden' }}>
              <Typography sx={{ color: 'text.primary' }} variant="subtitle2">
                {user.displayName}
              </Typography>
              <Typography noWrap sx={{ color: 'text.secondary' }} variant="body2">
                {user.email}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />
      {Boolean(size) && !isMobile && (
        <Box sx={{ mt: 10, pb: 3, px: 2.5 }}>
          <DocStyle>
            <DocIcon sx={{ height: 36, mb: 2, width: 36 }} />
            <Typography gutterBottom sx={{ color: 'grey.800' }} variant="subtitle1">
              Besoin d&apos;aide ?
            </Typography>
            <Typography sx={{ color: 'grey.600', mb: 2 }} variant="body2">
              Il y a {size} conseils à voir sur cette page !
            </Typography>
            <Button
              fullWidth
              onClick={() => {
                if (isOpenSidebar && onCloseSidebar) onCloseSidebar();
                handleOpen();
              }}
              variant="contained"
            >
              Voir les conseils
            </Button>
          </DocStyle>
        </Box>
      )}
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          PaperProps={{
            sx: {
              bgcolor: 'background.default',
              borderRight: theme.palette.mode === 'light' ? 'none' : `1px solid ${theme.palette.grey[800]}`,
              width: DRAWER_WIDTH,
            },
          }}
          onClose={onCloseSidebar}
          open={isOpenSidebar}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          PaperProps={{
            sx: { bgcolor: 'background.default', width: DRAWER_WIDTH },
          }}
          open
          variant="persistent"
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
};

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default DashboardSidebar;

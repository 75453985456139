import { useTheme } from '@material-ui/core';
import config from 'config';
import React from 'react';
import { Helmet } from 'react-helmet-async';

const ConfigProvider = () => {
  const theme = useTheme();

  if (!config?.public) return null;

  const { appleIconURL, faviconURL, manifestURL, title } = config.public;

  return (
    <>
      <Helmet>
        <link href={appleIconURL} rel="apple-touch-icon" sizes="144x144" />
        <link href={faviconURL} rel="icon" sizes="144x144" type="image/png" />
        <meta content={theme.palette.background.default} name="theme-color" />
        <link href={manifestURL} rel="manifest" />
        <title>{title}</title>
      </Helmet>
    </>
  );
};

export default ConfigProvider;

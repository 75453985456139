// material
import { ThemeProvider, alpha, createTheme, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

// hooks
import useSettings from '../hooks/useSettings';
//
import componentsOverride from '../theme/overrides';

// ----------------------------------------------------------------------

const ThemePrimaryColor = ({ children }) => {
  const outerTheme = useTheme();
  const { setColor } = useSettings();

  const themeOptions = useMemo(
    () => ({
      ...outerTheme,
      customShadows: {
        ...outerTheme.customShadows,
        primary: `0 8px 16px 0 ${alpha(setColor.main, 0.24)}`,
      },
      palette: {
        ...outerTheme.palette,
        primary: setColor,
      },
    }),
    [setColor, outerTheme]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

ThemePrimaryColor.propTypes = {
  children: PropTypes.node,
};

export default ThemePrimaryColor;

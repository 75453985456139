// cspell-checker: disable
// scroll bar
import 'simplebar/src/simplebar.css';
// map
import 'mapbox-gl/dist/mapbox-gl.css';
// lightbox
import 'react-image-lightbox/style.css';
// editor
import 'react-quill/dist/quill.snow.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// lazy image
// cspell-checker: disable
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// cspell-checker: enable
// i18n
import './locales/i18n';
// highlight
import './utils/highlight';

import { LoadingButton } from '@material-ui/lab';
// material
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
// redux
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import snackbar from 'utils/snackbar';

//
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorkerRegistration';

// ----------------------------------------------------------------------

ReactDOM.render(
  <HelmetProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SettingsProvider>
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </SettingsProvider>
    </LocalizationProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.register({
  onUpdate: (registration) => {
    const registrationWaiting = registration.waiting;
    if (registrationWaiting) {
      snackbar.enqueue('Votre application a été mise à jour', {
        action: () => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [loading, setLoading] = useState(false);

          return (
            <LoadingButton
              color="warning"
              loading={loading}
              onClick={() => {
                setLoading(true);
                registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
                registrationWaiting.addEventListener('statechange', (e) => {
                  if (e.target.state === 'activated') {
                    window.location.href = `${window.location.pathname}${window.location.search}`;
                  }
                });
              }}
              variant="outlined"
            >
              Rafraîchir
            </LoadingButton>
          );
        },
        persist: true,
        variant: 'warning',
      });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

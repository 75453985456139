import Label from 'components/Label';
import React from 'react';

const TypeLabel = ({ type }) => (
  <Label color="primary" variant="outlined">
    {type}
  </Label>
);

export default TypeLabel;

// ----------------------------------------------------------------------

const DISTANCE = 720;
const IN = { opacity: 1, scale: 1 };
const OUT = { opacity: 0, scale: 0 };

const TRANSITION_ENTER = {
  duration: 0.64,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const TRANSITION_EXIT = {
  duration: 0.48,
  ease: [0.43, 0.13, 0.23, 0.96],
};

export const varZoomIn = {
  animate: { ...IN, transition: TRANSITION_ENTER },
  exit: { ...OUT, transition: TRANSITION_EXIT },
  initial: OUT,
};

export const varZoomInUp = {
  animate: { ...IN, transition: TRANSITION_ENTER, translateY: 0 },
  exit: { ...OUT, transition: TRANSITION_EXIT, translateY: DISTANCE },
  initial: { ...OUT, translateY: DISTANCE },
};

export const varZoomInDown = {
  animate: { ...IN, transition: TRANSITION_ENTER, translateY: 0 },
  exit: { ...OUT, transition: TRANSITION_EXIT, translateY: -DISTANCE },
  initial: { ...OUT, translateY: -DISTANCE },
};

export const varZoomInLeft = {
  animate: { ...IN, transition: TRANSITION_ENTER, translateX: 0 },
  exit: { ...OUT, transition: TRANSITION_EXIT, translateX: -DISTANCE },
  initial: { ...OUT, translateX: -DISTANCE },
};

export const varZoomInRight = {
  animate: { ...IN, transition: TRANSITION_ENTER, translateX: 0 },
  exit: { ...OUT, transition: TRANSITION_EXIT, translateX: DISTANCE },
  initial: { ...OUT, translateX: DISTANCE },
};

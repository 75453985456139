import { Box } from '@material-ui/core';
import config from 'config';
import PropTypes from 'prop-types';
import React from 'react';

// ----------------------------------------------------------------------
const { logoURL } = config.public;

const Logo = ({ sx }) => (
  <Box sx={{ display: 'flex', height: 40, justifyContent: 'center', ...sx }}>
    <img alt="Socanor" height="40px" src={logoURL} width="auto" />
  </Box>
);

Logo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

export default Logo;

import { Box, Button, Divider, Typography } from '@material-ui/core';
// material
import { alpha } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import MyAvatar from '../../components/MyAvatar';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { logout, user } = useAuth();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <>
      <MIconButton
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          height: 44,
          padding: 0,
          width: 44,
          ...(open && {
            '&:before': {
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
              borderRadius: '50%',
              content: "''",
              height: '100%',
              position: 'absolute',
              width: '100%',
              zIndex: 1,
            },
          }),
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover anchorEl={anchorRef.current} onClose={handleClose} open={open} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography noWrap variant="subtitle1">
            {user.displayName}
          </Typography>
          <Typography noWrap sx={{ color: 'text.secondary' }} variant="body2">
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button color="inherit" fullWidth onClick={handleLogout} variant="outlined">
            Se déconnecter
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

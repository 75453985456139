import { Box, useTheme } from '@material-ui/core';
import useTutorial from 'hooks/useTutorial';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

const TutorialElement = ({ children, disableActions, fixed, step: stepProp, stepOptions, withoutContainer }) => {
  const elementRef = useRef();
  const childRef = useRef();
  const theme = useTheme();
  const { addElement, currentStep, open, scrolling } = useTutorial();
  const { pathname } = useLocation();
  const { body, bodyComponent, renderContent, step: stepOption, title } = stepOptions;
  const step = stepProp || stepOption;

  useEffect(() => {
    addElement({
      disableActions,
      fixed,
      on: pathname,
      ref: childRef,
      step,
      stepOptions: { body, bodyComponent, renderContent, title },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, childRef, elementRef]);

  return (
    <Box
      position="relative"
      ref={elementRef}
      zIndex={!scrolling && open && currentStep === step && theme.zIndex.tutorialBox + 1}
    >
      {withoutContainer
        ? React.cloneElement(children, { ref: childRef })
        : React.createElement('div', { ref: childRef }, children)}
    </Box>
  );
};

TutorialElement.defaultProps = {
  disableActions: false,
  fixed: false,
  step: undefined,
  stepOptions: {},
  withoutContainer: false,
};

TutorialElement.propTypes = {
  children: PropTypes.node.isRequired,
  disableActions: PropTypes.bool,
  fixed: PropTypes.bool,
  step: PropTypes.number,
  stepOptions: PropTypes.shape({
    body: PropTypes.string,
    bodyComponent: PropTypes.func,
    renderContent: PropTypes.func,
    title: PropTypes.string,
  }),
  withoutContainer: PropTypes.bool,
};

export default TutorialElement;

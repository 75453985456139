import { Box, Breadcrumbs, Link, Typography } from '@material-ui/core';
import { last } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
// material
import { Link as RouterLink } from 'react-router-dom';

// ----------------------------------------------------------------------

const Separator = <Box component="span" sx={{ bgcolor: 'text.disabled', borderRadius: '50%', height: 4, width: 4 }} />;

const LinkItem = ({ link }) => {
  const { href, icon, name } = link;
  return (
    <Link
      component={RouterLink}
      key={name}
      sx={{
        '& > div': { display: 'inherit' },
        alignItems: 'center',
        color: 'text.primary',
        display: 'flex',
        lineHeight: 2,
      }}
      to={href}
      variant="body2"
    >
      {icon && (
        <Box
          sx={{
            '& svg': { height: 20, width: 20 },
            mr: 1,
          }}
        >
          {icon}
        </Box>
      )}
      {name}
    </Link>
  );
};

LinkItem.defaultProps = {
  link: undefined,
};

LinkItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  link: PropTypes.object,
};

const MBreadcrumbs = ({ activeLast = false, links, ...other }) => {
  const currentLink = last(links).name;

  const listDefault = links.map((link) => <LinkItem key={link.name} link={link} />);
  const listActiveLast = links.map((link) => (
    <div key={link.name}>
      {link.name !== currentLink ? (
        <LinkItem link={link} />
      ) : (
        <Typography
          sx={{
            color: 'text.disabled',
            maxWidth: 260,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          variant="body2"
        >
          {currentLink}
        </Typography>
      )}
    </div>
  ));

  return (
    <Breadcrumbs separator={Separator} {...other}>
      {activeLast ? listDefault : listActiveLast}
    </Breadcrumbs>
  );
};

MBreadcrumbs.defaultProps = {
  activeLast: false,
};

MBreadcrumbs.propTypes = {
  activeLast: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  links: PropTypes.array.isRequired,
};

export default MBreadcrumbs;

import closeSquareOutline from '@iconify/icons-eva/close-square-outline';
import minusSquareOutline from '@iconify/icons-eva/minus-square-outline';
import plusSquareOutline from '@iconify/icons-eva/plus-square-outline';
import { Icon } from '@iconify/react';
import { Box } from '@material-ui/core';
import React from 'react';

// ----------------------------------------------------------------------

const ICON_SIZE = { height: 20, width: 20 };

export default function TreeView(theme) {
  return {
    MuiTreeItem: {
      styleOverrides: {
        iconContainer: { width: 'auto' },
        label: { ...theme.typography.body2 },
      },
    },
    MuiTreeView: {
      defaultProps: {
        defaultCollapseIcon: <Icon icon={minusSquareOutline} {...ICON_SIZE} />,
        defaultEndIcon: (
          <Box component={Icon} icon={closeSquareOutline} {...ICON_SIZE} sx={{ color: 'text.secondary' }} />
        ),
        defaultExpandIcon: <Icon icon={plusSquareOutline} {...ICON_SIZE} />,
      },
    },
  };
}

import { Box, Card, Container, Stack, Typography } from '@material-ui/core';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import CaravanIcon from 'assets/caravan';
import config from 'config';
// routes
import React from 'react';

import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
// components
import Page from '../../components/Page';

// hooks
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  maxWidth: 464,
  width: '100%',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: 'auto',
  maxWidth: 480,
  minHeight: '100vh',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const { siteName } = config;

const Login = () => (
  <RootStyle title="Connexion">
    <MHidden width="mdDown">
      <SectionStyle>
        <Box mx={10}>
          <CaravanIcon />
        </Box>
      </SectionStyle>
    </MHidden>

    <Container maxWidth="sm">
      <ContentStyle>
        <Stack alignItems="center" direction="row" sx={{ mb: 5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography gutterBottom variant="h4">
              {siteName}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Utilisez vos identifiants pour vous connecter.</Typography>
          </Box>
        </Stack>
        <LoginForm />
      </ContentStyle>
    </Container>
  </RootStyle>
);

export default Login;

import PropTypes from 'prop-types';
import React, { createContext, useEffect, useReducer } from 'react';

import apis from '../apis';
// utils
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  method: 'jwt',
});

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const token = window.localStorage.getItem('token');

        if (token && isValidToken(token)) {
          setSession(token);

          const response = await apis.me();
          const user = response.data?.data;

          dispatch({
            payload: {
              isAuthenticated: true,
              user,
            },
            type: 'INITIALIZE',
          });
        } else {
          dispatch({
            payload: {
              isAuthenticated: false,
              user: null,
            },
            type: 'INITIALIZE',
          });
        }
      } catch (err) {
        dispatch({
          payload: {
            isAuthenticated: false,
            user: null,
          },
          type: 'INITIALIZE',
        });
      }
    };

    initialize();
  }, []);

  const login = async (credentials) => {
    const response = await apis.login(credentials);
    const { token, user } = response.data?.data;

    setSession(token);
    dispatch({
      payload: {
        user,
      },
      type: 'LOGIN',
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        method: 'jwt',
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export { AuthContext, AuthProvider };

// ----------------------------------------------------------------------

function path(root, subLink) {
  return `${root}${subLink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: path('/login'),
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  documents: path(ROOTS_DASHBOARD, '/documents'),
  overview: path(ROOTS_DASHBOARD, '/overview'),
  pieces: path(ROOTS_DASHBOARD, '/pieces'),
  piecesReturn: path(ROOTS_DASHBOARD, '/retour-de-pieces'),
  recordDetails: path(ROOTS_DASHBOARD, '/dossiers/:id'),
  root: ROOTS_DASHBOARD,
  technical: path(ROOTS_DASHBOARD, '/techniques'),
};

import {
  Box,
  Button,
  Stack,
  Typography,
  experimentalStyled as styled,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import PWAIcon from 'assets/pwa';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';

const InstallAppStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[500_11],
  borderRadius: theme.shape.borderRadiusSm,
  padding: theme.spacing(2, 2.5),
}));

const InstallApp = () => {
  const [canAdd, setCanAdd] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();

  const handleInstall = useCallback(
    (e) => {
      e.preventDefault();
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(() => {
        setCanAdd(false);
        setDeferredPrompt(false);
      });
    },
    [deferredPrompt]
  );

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      setCanAdd(true);
      if (isMobile)
        enqueueSnackbar(`Vous pouvez installer l'application sur votre téléphone`, {
          action: () => (
            <Button color="success" onClick={handleInstall}>
              Installer
            </Button>
          ),
          variant: 'success',
        });
    });
  }, [enqueueSnackbar, handleInstall, isMobile]);

  if (!canAdd) return null;

  return (
    <InstallAppStyle>
      <Stack alignItems="center" spacing={2}>
        <Typography align="center" variant="caption">
          Installez l&apos;application pour téléphone / desktop
        </Typography>
        <Box width="100px">
          <PWAIcon />
        </Box>
        <Button onClick={handleInstall} size="small" variant="outlined">
          Installer
        </Button>
      </Stack>
    </InstallAppStyle>
  );
};

export default InstallApp;

import { Fab } from '@material-ui/core';
// material
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

//
import { ButtonAnimate } from '../animate';

// ----------------------------------------------------------------------

const MFab = forwardRef(({ children, color = 'primary', sx, ...other }, ref) => {
  const theme = useTheme();

  if (color === 'default' || color === 'inherit' || color === 'primary' || color === 'secondary') {
    return (
      <ButtonAnimate>
        <Fab color={color} ref={ref} sx={sx} {...other}>
          {children}
        </Fab>
      </ButtonAnimate>
    );
  }

  return (
    <ButtonAnimate>
      <Fab
        ref={ref}
        sx={{
          '&:hover': {
            bgcolor: theme.palette[color].dark,
          },
          bgcolor: theme.palette[color].main,
          boxShadow: theme.customShadows[color],
          color: theme.palette[color].contrastText,
          ...sx,
        }}
        {...other}
      >
        {children}
      </Fab>
    </ButtonAnimate>
  );
});

MFab.defaultProps = {
  children: undefined,
  color: 'inherit',
  sx: undefined,
};

MFab.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary', 'info', 'success', 'warning', 'error']),
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

export default MFab;

import config from 'config';
import PropTypes from 'prop-types';
import React, { createContext } from 'react';

// hooks
import useLocalStorage from '../hooks/useLocalStorage';
// theme
import palette from '../theme/palette';

// ----------------------------------------------------------------------

const PRIMARY_COLOR = [
  // DEFAULT
  {
    name: 'default',
    ...palette.light.primary,
  },
  // PURPLE
  {
    contrastText: '#fff',
    dark: '#431A9E',
    darker: '#200A69',
    light: '#B985F4',
    lighter: '#EBD6FD',
    main: '#7635dc',
    name: 'purple',
  },
  // CYAN
  {
    contrastText: palette.light.grey[800],
    dark: '#0E77B7',
    darker: '#053D7A',
    light: '#76F2FF',
    lighter: '#D1FFFC',
    main: '#1CCAFF',
    name: 'cyan',
  },
  // BLUE
  {
    contrastText: '#fff',
    dark: '#0027B7',
    darker: '#00137A',
    light: '#6697FF',
    lighter: '#CCDFFF',
    main: '#0045FF',
    name: 'blue',
  },
  // ORANGE
  {
    contrastText: palette.light.grey[800],
    dark: '#B66816',
    darker: '#793908',
    light: '#FED680',
    lighter: '#FEF4D4',
    main: '#fda92d',
    name: 'orange',
  },
  // RED
  {
    contrastText: '#fff',
    dark: '#B71833',
    darker: '#7A0930',
    light: '#FFC1AC',
    lighter: '#FFE3D5',
    main: '#FF3030',
    name: 'red',
  },
];

function SetColor(themeColor) {
  let color;
  const DEFAULT = PRIMARY_COLOR[0];
  const PURPLE = PRIMARY_COLOR[1];
  const CYAN = PRIMARY_COLOR[2];
  const BLUE = PRIMARY_COLOR[3];
  const ORANGE = PRIMARY_COLOR[4];
  const RED = PRIMARY_COLOR[5];

  switch (themeColor) {
    case 'purple':
      color = PURPLE;
      break;
    case 'cyan':
      color = CYAN;
      break;
    case 'blue':
      color = BLUE;
      break;
    case 'orange':
      color = ORANGE;
      break;
    case 'red':
      color = RED;
      break;
    default:
      color = DEFAULT;
  }
  return color;
}

const { themeColor, themeMode } = config;

const initialState = {
  colorOption: [],
  onChangeColor: () => {},
  onChangeDirection: () => {},
  onChangeMode: () => {},
  setColor: SetColor(themeColor),
  themeColor,
  themeDirection: 'ltr',
  themeMode,
};

const SettingsContext = createContext(initialState);

function SettingsProvider({ children }) {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeColor,
    themeDirection: 'ltr',
    themeMode,
  });

  const onChangeMode = (event) => {
    setSettings({
      ...settings,
      themeMode: event.target.value,
    });
  };

  const onChangeDirection = (event) => {
    setSettings({
      ...settings,
      themeDirection: event.target.value,
    });
  };

  const onChangeColor = (event) => {
    setSettings({
      ...settings,
      themeColor: event.target.value,
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,

        colorOption: PRIMARY_COLOR.map((color) => ({
          name: color.name,
          value: color.main,
        })),

        // Color
        onChangeColor,

        // Direction
        onChangeDirection,
        // Mode
        onChangeMode,
        setColor: SetColor(settings.themeColor),
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node,
};

export { SettingsProvider, SettingsContext };

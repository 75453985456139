import { Box } from '@material-ui/core';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import SimpleBarReact from 'simplebar-react';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
});

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
  // cspell-checker: disable-next-line
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
  // cspell-checker: disable-next-line
  '& .simplebar-scrollbar': {
    // cspell-checker: disable-next-line
    '&.simplebar-visible:before': {
      opacity: 1,
    },
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[600], 0.48),
    },
  },
  // cspell-checker: disable-next-line
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6,
  },
  // cspell-checker: disable-next-line
  '& .simplebar-track.simplebar-vertical': {
    width: 10,
  },
  maxHeight: '100%',
}));

// ----------------------------------------------------------------------

const Scrollbar = ({ children, sx, ...other }) => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      <SimpleBarStyle clickOnTrack={false} sx={sx} timeout={500} {...other}>
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
};

Scrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

export default Scrollbar;

// hooks
import React from 'react';

import useAuth from '../hooks/useAuth';
import createAvatar from '../utils/createAvatar';
//
import { MAvatar } from './@material-extend';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();

  return (
    <MAvatar
      alt={user.displayName}
      color={user.photoURL ? 'default' : createAvatar(user.displayName).color}
      src={user.photoURL}
      {...other}
    >
      {createAvatar(user.displayName).name}
    </MAvatar>
  );
}

import { sign, verify } from 'jsonwebtoken';
import jwtDecode from 'jwt-decode';

//
// eslint-disable-next-line import/no-cycle
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (token) => {
  if (!token) {
    return false;
  }

  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;
  expiredTimer = window.setTimeout(() => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  }, timeLeft);
};

const setSession = (token) => {
  if (token) {
    localStorage.setItem('token', token);
    // Unless until we add interceptor in apis.js
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    // This function below will handle when token is expired
    const { exp } = jwtDecode(token);
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, verify, sign };

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import useContacts from 'hooks/useContacts';
// import useContacts from 'hooks/useContacts';
import useIsMountedRef from 'hooks/useIsMountedRef';
import React from 'react';
import { fDate } from 'utils/formatTime';
import * as Yup from 'yup';

const ContactForm = ({ contact, onClose }) => {
  const creating = !contact;
  const isMountedRef = useIsMountedRef();
  const { createContact, updateContact } = useContacts();

  const ContactSchema = Yup.object().shape({
    FCT: Yup.string().max(100, 'Max. 100 caractères.'),
    MAIL: Yup.string()
      .email("L'email doit être une adresse valide")
      .required("L'email est requis.")
      .max(100, 'Max. 100 caractères.'),
    NOM: Yup.string().required('Le nom est requis.').max(100, 'Max. 100 caractères.'),
    PRENOM: Yup.string().required('Le prénom est requis.').max(100, 'Max. 100 caractères.'),
  });

  const formik = useFormik({
    initialValues: !creating
      ? contact
      : {
          FCT: '',
          MAIL: '',
          NOM: '',
          PRENOM: '',
        },
    onSubmit: async (contactData, { resetForm, setErrors, setSubmitting }) => {
      try {
        if (creating) {
          await createContact(contactData);
        } else {
          await updateContact(contact.ID_CONTACT, contactData);
        }
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        if (onClose) onClose();
      } catch (error) {
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: 'Erreur lors de la création du contact' });
        }
      }
    },
    validationSchema: ContactSchema,
  });

  const { errors, getFieldProps, handleSubmit, isSubmitting, touched, values } = formik;

  return (
    <FormikProvider value={formik}>
      <Dialog fullWidth maxWidth="sm" open>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>
            {creating && !(values.NOM || values.PRENOM) ? 'Nouveau contact' : `${values.PRENOM} ${values.NOM}`}
            {!creating && (
              <>
                <br />
                <Typography variant="caption">{`Mis à jour pour la dernière fois le ${fDate(
                  contact.DTEMODIF
                )}`}</Typography>
              </>
            )}
          </DialogTitle>
          <DialogContent>
            <Stack spacing={3}>
              {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
              <TextField
                autoComplete="PRENOM"
                fullWidth
                label="Prénom"
                required
                type="text"
                {...getFieldProps('PRENOM')}
                error={Boolean(touched.PRENOM && errors.PRENOM)}
                helperText={touched.PRENOM && errors.PRENOM}
              />
              <TextField
                autoComplete="NOM"
                fullWidth
                label="Nom"
                required
                type="text"
                {...getFieldProps('NOM')}
                error={Boolean(touched.NOM && errors.NOM)}
                helperText={touched.NOM && errors.NOM}
              />
              <TextField
                autoComplete="MAIL"
                fullWidth
                label="Adresse email"
                required
                type="email"
                {...getFieldProps('MAIL')}
                error={Boolean(touched.MAIL && errors.MAIL)}
                helperText={touched.MAIL && errors.MAIL}
              />
              <TextField
                autoComplete="FCT"
                fullWidth
                label="Fonction"
                type="text"
                {...getFieldProps('FCT')}
                error={Boolean(touched.FCT && errors.FCT)}
                helperText={touched.FCT && errors.FCT}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Annuler</Button>
            <LoadingButton autoFocus loading={isSubmitting} type="submit" variant="contained">
              Enregistrer
            </LoadingButton>
          </DialogActions>
        </Form>
      </Dialog>
    </FormikProvider>
  );
};

export default ContactForm;

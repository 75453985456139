import NotistackProvider from 'components/NotistackProvider';
import ThemePrimaryColor from 'components/ThemePrimaryColor';
import { SettingsProvider } from 'contexts/SettingsContext';
import { useSnackbar } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import ThemeConfig from 'theme';

export default {
  enqueue(message, options) {
    const Display = () => {
      const { enqueueSnackbar } = useSnackbar();
      enqueueSnackbar(message, options);
      return null;
    };

    const mountPoint = document.getElementById('snackbar-helper');

    ReactDOM.render(
      <SettingsProvider>
        <ThemeConfig>
          <ThemePrimaryColor>
            <NotistackProvider>
              <Display />
            </NotistackProvider>
          </ThemePrimaryColor>
        </ThemeConfig>
      </SettingsProvider>,
      mountPoint
    );
  },
};

import { alpha } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

export default function ButtonGroup(theme) {
  return {
    MuiButtonGroup: {
      styleOverrides: {
        grouped: {
          borderColor: `${theme.palette.grey[500_32]} !important`,
        },
        groupedContained: {
          color: theme.palette.grey[800],
        },
        groupedContainedPrimary: {
          borderColor: `${theme.palette.primary.dark} !important`,
          color: theme.palette.primary.contrastText,
        },
        groupedContainedSecondary: {
          borderColor: `${theme.palette.secondary.dark} !important`,
          color: theme.palette.secondary.contrastText,
        },
        groupedOutlinedPrimary: {
          borderColor: `${alpha(theme.palette.primary.main, 0.48)} !important`,
        },
        groupedOutlinedSecondary: {
          borderColor: `${alpha(theme.palette.secondary.main, 0.48)} !important`,
        },
        groupedTextPrimary: {
          borderColor: `${theme.palette.primary.main} !important`,
        },
        groupedTextSecondary: {
          borderColor: `${theme.palette.secondary.main} !important`,
        },
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },

      variants: [
        {
          props: { color: 'inherit', variant: 'contained' },
          style: { boxShadow: theme.customShadows.z8 },
        },
        {
          props: { color: 'primary', variant: 'contained' },
          style: { boxShadow: theme.customShadows.primary },
        },
        {
          props: { color: 'secondary', variant: 'contained' },
          style: { boxShadow: theme.customShadows.secondary },
        },
        {
          props: { disabled: true },
          style: {
            '& .MuiButtonGroup-grouped.Mui-disabled': {
              '&.MuiButton-contained': {
                backgroundColor: theme.palette.action.disabledBackground,
              },
              borderColor: `${theme.palette.action.disabledBackground} !important`,
              color: `${theme.palette.action.disabled} !important`,
            },
            boxShadow: 'none !important',
          },
        },
      ],
    },
  };
}

import { Button, Divider, Stack, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import MenuPopover from 'components/MenuPopover';
import React from 'react';

const renderActions = ({ handleClose, nextStep, prevStep, reset, size, stepIndex }) => {
  const closeAndReset = () => {
    handleClose();
    reset();
  };

  return (
    <Stack direction="row" spacing={2} sx={{ p: 1 }}>
      {stepIndex !== 0 && (
        <Button color="primary" fullWidth onClick={prevStep} variant="outlined">
          Précédent
        </Button>
      )}
      {stepIndex !== size - 1 && (
        <Button color="primary" fullWidth onClick={nextStep} variant="outlined">
          Suivant
        </Button>
      )}
      {stepIndex === size - 1 && (
        <Button color="primary" fullWidth onClick={closeAndReset} variant="contained">
          Fermer
        </Button>
      )}
    </Stack>
  );
};

const Dot = styled('div')(({ active, theme }) => ({
  backgroundColor: active ? theme.palette.primary.main : theme.palette.primary.lighter,
  borderRadius: '7px',
  cursor: 'pointer',
  height: '7px',
  width: active ? '14px' : '7px',
}));

const Dots = ({ onChangeStepIndex, size, stepIndex }) => (
  <Stack direction="row" justifyContent="center" spacing={1} sx={{ p: 2 }}>
    {Array.from({ length: size }).map((dot, iDot) => (
      <Dot active={stepIndex === iDot} onClick={() => onChangeStepIndex(iDot)} />
    ))}
  </Stack>
);

const TutorialPopover = ({
  handleClose,
  nextStep,
  onChangeStepIndex,
  open,
  prevStep,
  reset,
  scrolling,
  size,
  step,
  stepIndex,
  stepOptions,
  stepRef,
}) => {
  const { body, bodyComponent, title } = stepOptions;
  return (
    <MenuPopover anchorEl={stepRef} onClose={handleClose} open={open && !scrolling} sx={{ minWidth: 360 }}>
      <Typography sx={{ p: 2 }} variant="h6">
        {title || `#${step} Step`}
      </Typography>
      <Divider />
      {bodyComponent || <Typography sx={{ p: 2 }}>{body}</Typography>}
      <Typography align="center">
        <Dots onChangeStepIndex={onChangeStepIndex} size={size} stepIndex={stepIndex} />
      </Typography>
      <Divider />
      {renderActions({ handleClose, nextStep, prevStep, reset, size, stepIndex })}
    </MenuPopover>
  );
};

export default TutorialPopover;
